<template>
  <div :class="type" class="base-snackbar">
    <div class="base-snackbar__wrapper">
      <div class="base-snackbar__icon">
        <svg-icon class="base-snackbar__icon" :name='getIcon'/>
      </div>
      <div class="base-snackbar__text">
        {{text}}
      </div>
    </div>
    <div class="base-snackbar__icon_close" @click='close'>
     <svg-icon name='close'/>
     </div>
  </div>
</template>

<script>
  export default {
    props:{
      type:{
        type:String,
        default:'info'
      },
      text:{
        type:String,
        default:'text'
      }
    },
    computed:{
      getIcon(){
        return this.type=='success' ? 'success' : 'info'
      }
    },
    methods:{
      close(){
        this.$emit('close')
      }
    }
  }
</script>

<style lang="stylus" scoped>
@require '~@/assets/stylus/mixins/mixins';
@require '~@/assets/stylus/vars/variables';
$name='base-snackbar'
.{$name}
  position fixed
  bottom 80px
  flexy(space-between,center)
  width 354px
  height auto
  border-radius: 8px;
  padding 8px
  &__wrapper
    flexy(center,center)
  &__text
    margin-left 12px
    color: rgba(0, 0, 0, 0.67)
    font-weight: 400;
    font-size: 16px;
  &__icon
    color:$theme-light.secondary.base
    padding 4px
    flexy(center,center)
    width 36px
    height 36px
    border-radius: 8px;
    &_close
      width 24px
      height 24px
      cursor pointer
      color #999999
      
.info
  background:lighten($theme-light.primary.base,80%)
  .{$name}
    &__icon
      background $theme-light.primary.base
.danger
  background lighten($theme-light.danger.base,80%)
  .{$name}
    &__icon
      background $theme-light.danger.base
.warning
  background lighten($theme-light.danger.base,80%)
  .{$name}
    &__icon
      background $theme-light.warning.base
.success
  background lighten($theme-light.success.base,80%)
  .{$name}
    &__icon
      background $theme-light.success.base
        
</style>
