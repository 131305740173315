<template>
  <div class="base-switch">
    <input v-model="inputVal" class="base-switch__input" :id="id" type="checkbox"/>
    <label :class='{"base-switch__switch_check":inputVal}' class="base-switch__switch" base-switch__switch :for="id">
      <span v-if="inputVal">ON</span>
      <span v-else>OFF</span>
    </label>
  </div>
</template>

<script>
  export default {
    props:{
      value:{
        type:Boolean
      }
    },
    data(){
      return{
        id : this._uid
      }
    },
    computed:{
      inputVal: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit("input", value);
        },
      }
    }
  }
</script>

<style lang="stylus" scoped>
@require '~@/assets/stylus/mixins/mixins';
@require '~@/assets/stylus/vars/variables';
.base-switch
  &__input
    display:none
  &__switch
    flexy(flex-end, center)
    width 70px
    height 30px
    background: $theme-light.danger.base;
    position relative
    cursor:pointer
    border-radius: 5px;
    padding 0 5px;
    span
      color $theme-light.secondary.base;
    &:before
      position:absolute
      content:''
      width 25px
      height 25px
      left 5px
      background: $theme-light.secondary.base;
      border-radius: 5px;
      transition all 0.5s;

    &_check
      justify-content flex-start
      background: $theme-light.primary.base;
      &:before
        transform translateX(35px);
</style>
