<template>
    <div class="tpl-info">
      <div class='tpl-info__item tpl-info__tables'>
        <div
          class="table__wrapper tpl-info__table"
          v-for="(table,index) in getTables"
          :key="index"
        >
          <table class="table">
            <tr class="table__row table__row_header tpl-info__table_header">
            <th class="table__item tpl-info__table_col1">{{translate('name')}}</th>
            <th class="table__item tpl-info__table_col2">{{translate('value')}}</th>
            </tr>
            <tr class="table__row tpl-info__table_row" v-for="(value, name) in table" :key="name">
              <td class="table__item tpl-info__table_col1">{{translate(name)}}</td>
              <td class="table__item tpl-info__table_col2">{{value}}</td>
            </tr>
          </table>
        </div>
      </div>
      <!--<line-chart class="tpl-info__item" ref="loadCPU" :numbers='300'/>-->
      <!--<line-chart class="tpl-info__item" ref="loadRam" :numbers='300'/>-->

      <area-chart
        class="tpl-info__item"
        ref="loadCPU"
        :maxTicksLimitX='5'
        :maxTicksLimitY='10'
      />
      <area-chart
        class="tpl-info__item"
        ref="loadRam"
        :maxTicksLimitX='5'
        :maxTicksLimitY='10'
      />
    </div>
</template>

<script>
import {addPercent} from '@/helpers/formatedData.js'
import LocalizationMixin from '@/mixins/LocalizationMixin.js'
import UpdateDataMixin from '@/mixins/UpdateDataMixin.js'
import DataApi from '@/services/api/DataApi.js'
import breakpoints from '@/utils/breakpoints.js'
import LineChart from '../LineChart.vue'
import AreaChart from '../AreaChart.vue'

const freqUpdateInMs=1000

export default {
  mixins: [LocalizationMixin, UpdateDataMixin],
  components:{LineChart, AreaChart},
  data(){
    return{
      loading:false,
      items:{},
    }
  },
  props:{
    url:{
      type:String
    }
  },
  computed:{
    getTables(){
      if(!this.isMobile){
        let result=[]
        let array=Object.entries(this.items)
        let lenght=array.length
        result.push(Object.fromEntries(array.slice(0,Math.ceil(lenght/2))))
        result.push(Object.fromEntries(array.slice(Math.ceil(lenght/2),lenght)))
        return result
      }
      return [this.items]
    },
    screenWidth() {
      return this.$store.state.screen.width;
    },
    isMobile(){
      return this.screenWidth<breakpoints.md
    }
  },
  methods:{
    addPercent,
    fetchData(){
      this.loading=true
      return DataApi.get(this.url)
      .then((data)=>{
        this.items=data
        this.calcLoadRam(data)
        this.calcLoadCPU(data)
        this.loading=false
      })
    },
    getCurrentTime(){
      let now = new Date();
      var options = {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      };
      return now.toLocaleString("ru", options)
    },

    calcLoadRam({ram_total, ram_used}){
      this.$refs.loadRam.update(this.getCurrentTime(),
      [
        {name:'ram',y:ram_used}
      ])
    },

    calcLoadCPU({core0_load,core1_load}){
      this.$refs.loadCPU.update(
        this.getCurrentTime(),
        [
          {name:'core0',y:core0_load},
          {name:'core1',y:core1_load},
        ])
    }
  },
}
</script>

<style lang="stylus" scoped>
@require '~@/assets/stylus/mixins/mixins';
@require '~@/assets/stylus/vars/variables';

.table
  font-size: 12px;
  &__row
    height 38px

.tpl-info
  width 100%
  &__tables
    flexy(center,flex-start)
  &__item
    margin-bottom 40px
  &__table
    height 100%
    &:first-child
      margin-right:24px
      +breakpoint(sm-and-down){
        margin-right 0px
      }

    &_col1,
    &_col2
      width 50%
      padding-right 12px

    &_col1
      text-align right
    &_col2
      text-align left

</style>
