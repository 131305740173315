<template>
  <div class="base-layout">
    <base-menu
      class="base-layout__menu"
      v-model="isShow"
      :mobile="isMobile"
      :items="getTemplate.sidebar"
      @select="pushPage"
    />

    <div class="base-layout__page">
      <header class="base-layout__header">
        <div
          class="base-layout__btn"
          v-if="isMobile"
          @click="isShow = true"
        >
          <svg-icon name="burger"/>
        </div>

        <router-link to="/">
          <svg-icon v-if="!isMobile" class="base-layout__logo" name="logo"/>
        </router-link>

        <div class="base-layout__header_wrapper">
          <base-breadcrumbs v-if="!isMobile" :items="getParams"/>
          <div class="base-layout__wrapper">
            <base-btn class="base-menu config__btn"
                      @click="saveConfig"
                      :spinner="spinner">
              {{ translate('save-config') }}
            </base-btn>
            <lang-btn class="base-layout__lang"/>
            <input-select
              class="base-layout__user"
              :items='[translate("exit")]'
              :placeholder="getUsername"
              plain
              @input="logout"
            />
          </div>
        </div>
      </header>

      <div  class="base-layout__content">
        <base-breadcrumbs
          v-if="isMobile"
          class="base-layout__breadcrumbs"
          :items="getParams"
        />

        <router-view v-show="!isLoading"/>
      </div>
    </div>
  </div>
</template>

<script>
// import * as sock from 'websocket'
import LangBtn from '@/components/LangBtn.vue'
import ProfileBtn from '@/components/ProfileBtn.vue'
import BaseBreadcrumbs from '@/components/BaseBreadcrumbs.vue'
import LocalizationMixin from '@/mixins/LocalizationMixin.js'
import { deletePercent, addPercent } from '@/helpers/formatedData.js'
import breakpoints from '@/utils/breakpoints.js'
import DataApi from '@/services/api/DataApi.js'

export default {
  name: 'BaseLayout',
  mixins: [LocalizationMixin],
  metaInfo() {
    return {
      title: this.deviceInfo ? this.deviceInfo.hostname : ''
    }
  },
  components: {
    LangBtn,
    ProfileBtn,
    BaseBreadcrumbs
  },
  data() {
    return {
      isShow: false,
      loading: false,
      spinner: false,
      menuVisible: true,
      ws: null
    }
  },
  computed: {
    getTemplate() {
      return this.$store.getters['frontend/getTemplate']
    },
    getIcons() {
      return this.$store.getters['frontend/getIcons']
    },
    getParams() {
      return this.$route.params
    },
    screenWidth() {
      return this.$store.state.screen.width
    },
    isMobile() {
      return this.screenWidth < breakpoints.md
    },
    isLoading() {
      return this.$store.getters['getLoading']
    },
    deviceInfo() {
      return this.$store.getters['frontend/getDeviceInfo']
    },
    getUsername() {
      const user = this.$store.getters['auth/getUser']
      return user ? user.name : 'Admin' //FIXME
    }
  },
  mounted() {
    if (!this.isMobile) {
      this.isShow = true
    }
  },
  methods: {
    addPercent,
    deletePercent,
    toggleMenu() {
      this.menuVisible = !this.menuVisible
    },
    pushPage(selected) {
      const { menu, submenu } = selected
      if (this.isMobile) {
        this.isShow = false
      }
      this.$router.push({
        name: 'menu',
        params: {
          menu: this.deletePercent(menu),
          submenu: submenu ? this.deletePercent(submenu) : null
        }
      }).catch(()=>{});
    },
    logout() {
      this.$store.dispatch('auth/logout')
    },
    saveConfig() {
      this.spinner = true;
      DataApi.post('/api/v1/config-save', {})
      .finally(() => this.spinner = false)
    }
  }
}
</script>

<style lang="stylus">
@require '~@/assets/stylus/mixins/mixins';
.md-list-item-content
  div
    flexy(flex-start,center)
    width 100%
.md-list-expand
  .md-list-item-content
    padding-left 35px !important
.md-active
  &>.md-list-item-content
    background: var(--md-theme-default-primary) !important
</style>
<style lang="stylus" scoped>
@require '~@/assets/stylus/mixins/mixins';
@require '~@/assets/stylus/vars/variables';
$height-header=90px
$width-menu=260px

.base-layout
  flexy(space-between,flex-start)
  width 100%
  height 100%
  &__menu
    z-index 9998
    width $width-menu
  &__header
    z-index 9999
    position fixed
    flexy(space-between,center)
    background: $theme-light.secondary.base
    width 100%
    height $height-header
    padding 0px 24px
    +breakpoint(sm-and-down){
      z-index 9997
    }
    &_wrapper
      flexy(space-between,center)
      width 100%
      height 100%
  &__page
    width 100%
  &__logo
    width 188px
    height 52px
    margin-right 100px
    +breakpoint(sm-and-down){
      margin 0px
    }
  &__content
    margin-top $height-header+30px
    margin-left $width-menu
    padding 0px 24px
    position relative
    +breakpoint(sm-and-down){
      padding 0px 20px
      margin-left 0px
      width 100%
    }
  &__btn
    cursor pointer
  &__breadcrumbs
    width:100%
    +breakpoint(md-and-down){
      margin-bottom:24px
    }
  &__wrapper
    flexy(center,center)
    margin-left auto
  &__user
    width 100px
  &__lang
    margin-right 50px
  .config__btn
    width 215px
    margin-right 50px
</style>
