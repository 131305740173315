var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tpl-info"},[_c('div',{staticClass:"tpl-info__item tpl-info__tables"},[_c('div',{staticClass:"table__wrapper tpl-info__table"},[_c('div',{staticClass:"tpl tpl-update"},[_c('h3',{staticClass:"tpl__header"},[_vm._v(_vm._s(_vm.translate(_vm.title)))]),_c('table',{staticClass:"table tpl-info__table"},_vm._l((_vm.displayedRecords),function(value,name){return _c('tr',{key:name,staticClass:"table__row tpl-info__table_row"},[_c('td',{staticClass:"table__item tpl-info__table_col"},[_vm._v(_vm._s(value))])])}),0)])]),_c('pagination',{attrs:{"records":_vm.items.length,"per-page":_vm.pagination,"options":{
        texts: {
          count: '',
          prevChunk: '‹‹',
          prevPage: '‹',
          nextChunk: '››',
          nextPage: '›'
        }
      }},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }