<template>
  <div class="project-input" :class="getValidationClass">
    <component
      :is="config.name"
      v-model="input"
      class="project-input__input"
      :error="error || $v.input.$invalid"
      :is-hidden="isHidden"
      :class="config.class"
      :items="config.items"
    ></component>
    <!-- <span class="md-error" v-if="!$v.input.invalid">{{translate('errorIncorrect')}}</span> -->
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, helpers } from 'vuelidate/lib/validators'
import LocalizationMixin from '@/mixins/LocalizationMixin.js'

export default {
  mixins: [
    validationMixin,
    LocalizationMixin
  ],
  props: {
    value: {
      default: ''
    },
    config: {
      default: () => {}
    },
    placeholder: {
      default: ''
    },
    isHidden: { type: Boolean, default: false },
    error: { type: Boolean, default: false }
  },
  data() {
    return {
      input: ''
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    getValidationClass () {
      const field = this.$v.input
      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
  },
  watch: {
    value(val) {
      this.input = val
    },
    input(val) {
      this.$emit('input', val)
    },
    '$v.input.$invalid': {
      handler(val) {
        this.$emit('validate', val)
      }
    }
  },
  validations() {
    const validator = this.config.validator
    return {
      input: {
        required,
        regex: validator ? (value) => value.toString().search(new RegExp(validator)) !== -1 : false
      }
    }
  },
  mounted() {
    this.input = this.value
  },
  methods: {
    validate() {
      this.$v.input.$touch()
    }
  }
}
</script>

<style lang="stylus" scoped>
@require '~@/assets/stylus/mixins/mixins';
@require '~@/assets/stylus/vars/variables';
.project-input
    width 100%
.md-input
    font-size: 13px !important
    line-height: 18px
    &::placeholder
        font-size: 13px !important
</style>
