<template>
  <div class="tpl-info">
    <div class='tpl-info__item tpl-info__tables'>
      <div class="table__wrapper tpl-info__table">
        <div class="tpl tpl-update">
        <h3 class="tpl__header">{{ translate(title) }}</h3>
        <table class="table tpl-info__table">
          <tr class="table__row tpl-info__table_row" v-for="(value, name) in displayedRecords" :key="name">
            <td class="table__item tpl-info__table_col">{{value}}</td>
          </tr>
        </table>
        </div>
      </div>
      <pagination
        :records="items.length"
        v-model="page"
        :per-page="pagination"
        :options="{
          texts: {
            count: '',
            prevChunk: '‹‹',
            prevPage: '‹',
            nextChunk: '››',
            nextPage: '›'
          }
        }"
      >
      </pagination>
    </div>
  </div>
</template>

<script>
import LocalizationMixin from '@/mixins/LocalizationMixin.js'
import UpdateDataMixin from '@/mixins/UpdateDataMixin.js'
import DataApi from '@/services/api/DataApi.js'
import Pagination from 'vue-pagination-2';
export default {
  mixins: [LocalizationMixin, UpdateDataMixin],
  components:{Pagination},
  data(){
    return{
      loading:false,
      items: '',
      page: 1
    }
  },
  props:{
    url:{
      type:String
    },
    title: {
      type:String
    },
    pagination: {
      type:Number
    },
    isDescendingSort: {
      type: Boolean
    },
  },
  computed: {
    displayedRecords() {
      const startIndex = this.pagination * (this.page - 1);
      const endIndex = startIndex + this.pagination;
      return this.items.slice(startIndex, endIndex);
    }
  },
  methods: {
    fetchData(){
      this.loading=true;
      return DataApi.get(this.url)
      .then((data)=>{
        this.items = data.split('\n');
        this.items = this.items.filter((item) => {
          return !!item && item.length > 0
        });
        if (this.isDescendingSort) {
          this.items.reverse();
        }
        this.loading=false
      })
    },
  },
  mounted(){
    this.fetchData()
  },
}
</script>

<style lang="stylus" scoped>
  @require '~@/assets/stylus/mixins/mixins';
  @require '~@/assets/stylus/vars/variables';

  .table
    font-size: 12px;
    &__row
      height 38px

  .tpl-info
    width 100%
    &__table
      padding-bottom 16px
  .tpl
    max-width 100%!important
    padding-bottom 12px!important
    &__header
      margin-bottom 0px




</style>
