import router from '@/router'
import AuthApi from '@/services/api/AuthApi.js'

function parseJwt(token) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))

  return JSON.parse(jsonPayload)
}

export default {
  namespaced: true,
  state: () => ({
    auth: false,
    token: '',
    user: {
      name:''
    }
  }),
  getters: {
    isAuth(state) {
      return state.auth
    },
    getUser(state) {
      return state.user
    }
  },
  mutations: {
    setAuth(state, { authState, token, username }) {
      state.auth = authState
      state.token = token
      state.user.name = username
      window.localStorage.setItem('plc-token', token)
    }
  },
  actions: {
    login({ commit }, authData) {
      return AuthApi.login(authData)
        .then((response) => {
          if (response && response.data) {
            const jwtPayload = parseJwt(response.data)
            commit('setAuth', { authState: true, token: response.data, username: jwtPayload.username })
            AuthApi.apiService.defaults.headers["Authorization"] = `Bearer ${response.data}`
          }
        })
        .finally()
    },
    logout({ commit }) {
        commit('setAuth', { AuthState: false, token: '', username: '' })
        router.push('/auth').catch(error => {
          if (error.name !== "NavigationDuplicated") {
            throw error;
          }
        });
    }
  }
}
