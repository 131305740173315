import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth.js'
import frontend from './modules/frontend.js'
import localization from './modules/localization.js'
import Snackbar from '@/models/Snackbar.js'

import { authPersist } from "./helpers/persist";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    snackbars: [],
    apiError: false,
    screen: {
      width: 0,
      height: 0,
    },
    loading:false
  },
  getters: {
    getSnackbars (state) {
      return state.snackbars
    },
    getLoading (state) {
      return state.loading
    }
  },
  mutations: {
    addSnackbar (state, snackbar) {
      state.snackbars.push(snackbar)
    },

    setApiError(state, payload) {
      state.apiError=payload;
    },

    deleteSnackbar (state, id) {
      state.snackbars = state.snackbars.filter(item => item.id !== id)
    },
    setScreen(state, value) {
      state.screen = value;
    },
    setLoading(state,value){
      state.loading=value
    }
  },
  actions: {
    showSnackbar ({ commit }, data) {
      const snackbar = new Snackbar(data)
      commit('addSnackbar', snackbar)

      function hideSnackbar () {
        commit('deleteSnackbar', snackbar?.id)
      }

      setTimeout(hideSnackbar.bind(this), data.timeout)
    },

    showApiError({commit, state}, data) {
      if(!state.apiError) {
        const snackbar = new Snackbar(data)
        commit('addSnackbar', snackbar)
        commit('setApiError', true)
      }
    },

    hideApiError({commit, state}) {
      if(state.apiError) {
        const snackbar=state.snackbars.find(item => item.meta==='api')
        commit('deleteSnackbar', snackbar?.id)
        commit('setApiError', false)
      }
    },

    hideSnackbar({ commit }, id){
      commit('deleteSnackbar', id)
    }
  },
  modules: {
    auth,
    frontend,
    localization
  },
  plugins: [authPersist]
})
