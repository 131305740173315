import DataApi from '@/services/api/DataApi'
import configFrontend from '@/assets/config/frontend.json'
import configGuiFilters from '@/assets/config/gui-filters.json'
import configGuiHiding from '@/assets/config/gui-hiding.json'

export default {
  namespaced: true,
  state: () => ({
    template: null,
    gui: null,
    icons: {
      "%info%": require('@/assets/icons/information-outline.svg'),
      "%configuration%": require('@/assets/icons/information-outline.svg'),
      "%services%": require('@/assets/icons/information-outline.svg'),
      "%scripts%": require('@/assets/icons/information-outline.svg'),
      "%configeditor%": require('@/assets/icons/information-outline.svg'),
      "%users%": require('@/assets/icons/information-outline.svg'),
      "%network%": require('@/assets/icons/information-outline.svg'),
      "%system%": require('@/assets/icons/information-outline.svg'),
      "%webserver%": require('@/assets/icons/information-outline.svg'),
      "%ntp%": require('@/assets/icons/information-outline.svg'),
      "%snmp%": require('@/assets/icons/information-outline.svg'),
      "%list%": require('@/assets/icons/information-outline.svg'),
      "%luaeditor%": require('@/assets/icons/information-outline.svg'),
      "%radio%": require('@/assets/icons/information-outline.svg')
    },
    device: {}
  }),
  getters: {
    getTemplate(state) {
      return state.template
    },
    getIcons(state) {
      return state.icons
    },
    getTypes(state) {
      return state.gui.types
    },
    getFilters(state) {
      return state.gui.filters
    },
    getHiding(state) {
      return state.gui.hiding
    },
    getDeviceInfo(state) {
      return state.device
    }
  },
  mutations: {
    setTemplate(state, data) {
      state.template = data
    },
    setGui(state, data) {
      state.gui = data
    },
    setHiding(state, data) {
      state.gui.hiding = data.hiding
    },
    setDeviceInfo(state, data) {
      state.device = data
    }
  },
  actions: {
    fetchTemplate({ commit }) {
      commit('setTemplate', configFrontend)
      return configFrontend
    },
    fetchGui({ commit }) {
      commit('setGui', configGuiFilters)
    },
    fetchHiding({ commit }) {
      commit('setHiding', configGuiHiding)
    },
    fetchDeviceInfo({ commit }) {
      return DataApi.get('api/v1/config/system')
        .then((response) => {
          commit('setDeviceInfo', response)
        })
    }
  }
}
