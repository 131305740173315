class ComponentText {
  name = 'base-input'
  validator = ''
  constructor(validator) {
    this.validator = validator
  }
}

class ComponentBoolean {
  name = 'base-switch'
  class = ['md-primary']
  constructor() {
  }
}

class ComponentSelect {
  name = 'input-select'
  items = []
  constructor(items) {
    this.items = items
  }
}

export default {
  computed: {
    getTypes() {
      return this.$store.getters['frontend/getTypes']
    },
    getFilters() {
      return this.$store.getters['frontend/getFilters']
    },
    getParams() {
      return this.$route.params
    }
  },
  methods: {
    getComponent(val) {
      let result
      let type
      const { menu, submenu } = this.getParams

      if (this.getFilters[`${menu}-${submenu}-${val}`]) {
        type = this.getFilters[`${menu}-${submenu}-${val}`]
      } else if(this.getFilters[`${menu}-*-${val}`]) {
        type = this.getFilters[`${menu}-*-${val}`]
      } else if(this.getFilters[`${menu}-${val}`]) {
        type = this.getFilters[`${menu}-${val}`]
      } else if(this.getFilters[`${submenu}-${val}`]) {
        type = this.getFilters[`${submenu}-${val}`]
      } else if(this.getFilters[`${submenu}-*-${val}`]) {
        type = this.getFilters[`${submenu}-*-${val}`]
      } else if(this.getFilters[`${val}`]) {
        type = this.getFilters[`${val}`]
      }

      if (type === 'bool') {
        result = new ComponentBoolean()
      } else if (Array.isArray(this.getTypes[type])) {
        result = new ComponentSelect(this.getTypes[type])
      } else if (typeof this.getTypes[type] === 'string') {
        result = new ComponentText(this.getTypes[type])
      } else {
        result = new ComponentText()
      }
      return result
    },
    getComponentType(val) {
      return this.getFilters[val]
    }
  }
}
