<template>
  <div class="authPage">
    <lang-btn class="authPage__lang"></lang-btn>
    <form novalidate class="md-layout authPage__form card-light" @submit.prevent="validateUser">
      <svg-icon class="authPage__logo" name="logo"></svg-icon>
      <h3 class="authPage__header">{{ translate('loginform') }}</h3>

      <base-input
        v-model="username"
        class="authPage__input"
        :error="showError && getErrorUsername"
        :name="translate('inputLogin')"
        :placeholder="translate('username')"
      />

      <base-input
        v-model="password"
        class="authPage__input"
        :error="showError && getErrorPassword"
        :name="translate('inputPassword')"
        :placeholder="translate('password')"
        :is-hidden="getComponentHidingValue('password')"
      />

      <base-btn class="authPage__btn">
        {{ translate('login') }}
      </base-btn>

    </form>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import LocalizationMixin from '@/mixins/LocalizationMixin.js'
import FiltersMixin from '@/mixins/FiltersMixin'
import HidingMixin from '@/mixins/HidingMixin'
import {required,minLength,email} from 'vuelidate/lib/validators'
import LangBtn from '@/components/LangBtn.vue'

export default {
  mixins: [
    validationMixin,
    LocalizationMixin,
    FiltersMixin,
    HidingMixin
  ],
  components: { LangBtn },
  data() {
    return {
      loading: false,
      showError: false,
      username: '',
      password: ''
    }
  },
  computed:{
    getErrorUsername() {
      return this.$v.$invalid ? !this.$v.username.required || !this.$v.username.minLength : false
    },
    getErrorPassword() {
      return this.$v.$invalid ? !this.$v.password.required || !this.$v.password.minLength : false
    }
  },
  validations: {
    username: {
      required,
      minLength: minLength(3)
    },
    password: {
      required,
      minLength: minLength(3)
    }
  },
  methods: {
    validateUser() {
      const { username, password } = this
      this.$v.$touch()
      this.showError = true
      if (!this.$v.$invalid) {
        this.loading = true
        this.$store.dispatch('auth/login', { username, password })
          .then((response) => {
            window.localStorage.setItem('login', username);
            if (this.$store.getters["auth/isAuth"]) {
              this.$router.go({ name: 'menu', params: { menu:'info',submenu: 'systeminfo' } })
            }
          })
          .catch((error) => {
            console.log('error', error);
          })
          .finally(() => this.loading = false)
      }
    }
  },
  created () {
    this.username = window.localStorage.getItem('login');

  }
}
</script>

<style lang="stylus" scoped>
@require '~@/assets/stylus/mixins/mixins';
@require '~@/assets/stylus/vars/variables';
.authPage
  width 100%
  height 100vh
  flexy(center,center)
  +breakpoint(md-and-down){
    padding 20px
  }
  &__logo
    width 146px
    height 53px
  &__header
    text-align center
    font-weight: 500;
    font-size: 20px;
    margin 20px 0px
  &__input
    margin-bottom 30px
  &__form
    flexy(center,center,nowrap,column)
    padding 40px
    width 450px
  &__btn
    width 100%
    margin-top 20px
  &__loading
    height 10px
  &__lang
    position absolute !important
    right 60px
    top 60px
    +breakpoint(ms-and-down){
      display none
    }
>>>.md-icon
  &:after
    background: none !important
</style>
