<template>
  <div class="tpl-complex-table">
    <div style="display:flex">
      <base-btn
        v-if="expandable"
        class="tpl-complex-table__btn"
        prefixIcon="plus"
        @click="showModal(items.data[0],'add')"
      >
        {{ translate('create') }}
      </base-btn>
      <base-btn
        v-if="expandable"
        class="tpl-complex-table__btn"
        style="margin-left: 12px"
        @click="openFileDialog"
      >
        {{ translate('upload') }}
      </base-btn>
      <input
        ref="file"
        class="base-file__input"
        type="file"
        @change="handleFileUpload()"
      />
    </div>

    <div class="table__wrapper">
      <table class="table tpl-complex-table__table">
        <tr class="table__row table__row_header tpl-complex-table__table_header">
          <th class="table__item tpl-complex-table__table_col">{{ translate('name') }}</th>
          <th
            v-for="(item) in getHeaders"
            :key="item"
            class="table__item tpl-complex-table__table_col"
          >
            {{ translate(item) }}
          </th>
          <th class="table__item tpl-table__table_col">{{ translate('actions') }}</th>
        </tr>
        <tr
          v-for="(item, index) in items.data"
          :key="index"
          class="table__row tpl-complex-table__table_row"
        >
          <td class="table__item tpl-table__table_col">
            {{ item.name }}
          </td>
          <td
            v-for="head in getHeaders"
            :key="head"
            class="table__item tpl-table__table_col"
          >
            {{ item.value[head] }}
          </td>
          <td class="table__item tpl-complex-table__table_col">
            <svg-icon
              v-if="expandable"
              class="table__btn_icon"
              name="basket"
              @click="deleteItem(item)"
            ></svg-icon>
            <svg-icon
              class="table__btn_icon"
              name="edit"
              style="margin-right: 26.75px"
              @click="showModal(item,'update')"
            ></svg-icon>
            <svg-icon
              class="table__btn_icon"
              name="download"
              @click="downloadFile(item.name)"
            ></svg-icon>
          </td>
        </tr>
      </table>
    </div>

    <base-dialog v-model="dialog.state">
      <div class="dialog">
        <div class="dialog__header">
          <h3>{{ dialog.item ? dialog.item.name : "" }}</h3>
        </div>
        <div v-if="dialog.item" class="dialog__body">
          <div
            v-for="(item, name, index) in dialog.item.value"
            :key="index"
            class="dialog__form"
          >
            <div class="dialog__text">{{ translate(name) }}</div>
            <project-input
              v-model="dialog.form[name]"
              :ref="name"
              :config="getComponent(name)"
              :is-hidden="getComponentHidingValue(name)"
              :placeholder="item.value"
              class="dialog__input"
              @validate="validate"
            ></project-input>
          </div>
        </div>
        <div class="dialog__footer">
          <base-btn
            class="dialog__footer-btn"
            outlined
            @click="dialog.state=false"
          >
            {{translate('cancel')}}
          </base-btn>
          <base-btn
            class="dialog__footer-btn"
            :disabled="dialog.validation"
            @click="edit"
          >
            {{translate('save')}}
          </base-btn>
        </div>
      </div>
    </base-dialog>
  </div>
</template>

<script>
import { addPercent } from '@/helpers/formatedData.js'
import { createFile } from '@/helpers/files'
import LocalizationMixin from '@/mixins/LocalizationMixin.js'
import UpdateDataMixin from '@/mixins/UpdateDataMixin.js'
import FiltersMixin from '@/mixins/FiltersMixin.js'
import HidingMixin from '@/mixins/HidingMixin.js'
import DataApi from '@/services/api/DataApi.js'
import getNamePath from '@/helpers/substrPath.js'
import breakpoints from '@/utils/breakpoints.js'

class TplTableItem {
  name = ''
  value = ''
  constructor(key, value) {
    this.name = key
    this.value = value
  }
}
class TplTable {
  data = []
  constructor(items) {
    for (let key in items) {
      this.data.push(new TplTableItem(key,items[key]))
    }
  }
}
export default {
  mixins: [
    LocalizationMixin,
    FiltersMixin,
    HidingMixin,
    UpdateDataMixin
  ],
  data() {
    return {
      dialog: {
        state: false,
        item: null,
        mode: 'update',
        form: {},
        validation: false
      },
      namePath: ''
    }
  },
  props: {
    expandable: {
      type: Boolean,
      default: false
    },
    url: {
      type: String
    }
  },
  computed: {
    getHeaders() {
      if (this.items.data) {
        const data = this.items.data.sort((a,b) => b.value.length - a.value.length)
        if (this.items.data[0]) {
          return Object.keys(this.items.data[0].value)
        }
      }
      return []
    },
    screenWidth() {
      return this.$store.state.screen.width
    },
    isMobile() {
      return this.screenWidth < breakpoints.md
    },
    isInvalid() {
      let invalid = false
      let inputs = this.$refs
      for (let key in inputs) {
        let input = inputs[key][0]
        input.validate()
        if (input.$v.$invalid) {
          invalid = true
        }
      }
      return invalid
    }
  },
  created() {
    this.namePath = getNamePath(this.url)
  },
  methods: {
    addPercent,
    showModal(item, mode) {
      this.dialog.state = true
      this.dialog.mode = mode
      if (mode === 'add') {
        this.dialog.item = {
          value: {
            name: '',
            ...item.value
          }
        }
        this.dialog.form = { name: '', ...item.value }
      } else {
        this.dialog.item = item
        this.dialog.form = { ...item.value }
      }
    },
    fetchData() {
      this.loading = true
      return DataApi.get(this.url)
        .then((data)=> {
          this.items = new TplTable(data)
          this.loading = false
        })
    },
    edit(item) {
      if (!this.isInvalid) {
        const { form } = this.dialog
        DataApi.update(this.url,{ [form.name]: { enabled: form.enabled } })
          .then(() => {
            this.dialog.state = false
          })
      }
    },
    validate(state) {
      this.dialog.validation = state
    },
    deleteItem({ name }) {
      DataApi.delete(this.url, name)
    },
    addItem() {
      if (!this.isInvalid) {
        DataApi.add({ ...this.items.data, [this.dialog.form.name] : { ...this.dialog.form } })
      }
    },
    downloadFile(filename) {
      const url = `api/v1/scripts/${filename}`
      DataApi.get(url)
        .then((data) => {
          createFile(data, filename)
        })
    },
    openFileDialog() {
      this.$refs.file.click()
    },
    handleFileUpload() {
      const file = this.$refs.file.files[0]
      const filename = file.name

      const reader = new FileReader()
      reader.readAsText(file)
      reader.onload = (e) => {
        const fileContent = e.target.result
        DataApi.post_plain(`/api/v1/scripts/${filename}`, fileContent)
          .then(() => {
            this.fetchData()
          })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@require '~@/assets/stylus/mixins/mixins';
@require '~@/assets/stylus/vars/variables';

.tpl-complex-table
  overflow-x:auto
  &__btn
    flexy(center,center)
    width 127px !important
    margin-bottom 24px
    +breakpoint(ms-and-down){
        width 100% !important
    }
.dialog
  min-width 320px
  width 575px
  background: #FFFFFF;
  border-radius: 12px;
  +breakpoint(sm-and-down){
    width 100%
  }
  &__header
    flexy(center,center)
    text-align:center
    background: #F3F2F7;
    height 41px
    color: #858585;
    font-weight: 500;
    font-size: 18px;
    +breakpoint(sm-and-down){
      justify-content:flex-start
      background none
      margin 16px
      margin-top 24px
      border-bottom: 1px solid #E9ECEF;
      color: #2E2F31;
    }
  &__body
    flexy(flex-start,flex-start,nowrap,column)
    width 100%
    margin-top 21px
    margin-bottom 24px
    padding 0px 40px
    +breakpoint(sm-and-down){
      padding 0px 16px
    }
  &__footer
    padding 40px
    padding-top:0px
    flexy(center,center)
    +breakpoint(sm-and-down){
      padding 16px
      padding-top:0px
    }
    &-btn:first-child
      margin-right 10px
  &__form
    flexy(flex-start,center)
    width 100%
    margin-bottom 12px
    +breakpoint(sm-and-down){
      font-weight: 400;
      font-size: 12px;
      flexy(flex-start,center,wrap)
    }
  &__text
    width 30%
    +breakpoint(sm-and-down){
      margin-bottom 6px
    }
  &__input
    width 70%
.base-file
  &__input
    display:none
</style>
