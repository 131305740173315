<template>
    <div class="tpl-complex-table">
        <base-btn @click="showModal(items.data[0],'add')" v-if="expandable" class="tpl-complex-table__btn" prefixIcon="plus">
          {{translate('add')}}
        </base-btn>

        <div class="table__wrapper table__wrapper__container">
          <table class="table tpl-complex-table__table">
          <tr class="table__row table__row_header tpl-complex-table__table_header">
            <th class="table__item tpl-complex-table__table_col">{{translate('name')}}</th>
            <th v-for="(item) in  getHeaders" :key="item" class="table__item tpl-complex-table__table_col">{{translate(item)}}</th>
            <th  class="table__item tpl-table__table_col">{{translate('actions')}}</th>
          </tr>
          <tr class="table__row tpl-complex-table__table_row"  v-for="(item, index) in items.data" :key="index">
            <td class="table__item tpl-table__table_col">{{item.name}}</td>
            <td class="table__item tpl-table__table_col" v-for="head in getHeaders" :key="head">
              {{getValue(item, head)}}
            </td>
            <td class="table__item tpl-complex-table__table_col icons-col">
              <svg-icon @click="showModal(item,'update')" class="table__btn_icon" name='edit'/>
              <svg-icon @click="deleteItem(item)" v-if="expandable" class="table__btn_icon" name='basket'/>
            </td>
          </tr>
        </table>
        </div>

        <VDialog
          v-model='dialog'
          @clickCancel="dialog.state=false"
          @clickSave="onClickSave"
        />
    </div>
</template>

<script>
import {addPercent} from '@/helpers/formatedData.js'
import LocalizationMixin from '@/mixins/LocalizationMixin.js'
import UpdateDataMixin from '@/mixins/UpdateDataMixin.js'
import FiltersMixin from '@/mixins/FiltersMixin.js'
import HidingMixin from '@/mixins/HidingMixin'
import DataApi from '@/services/api/DataApi.js'
import getNamePath from '@/helpers/substrPath.js'
import breakpoints from '@/utils/breakpoints.js'

const VDialog = () => import('@/components/common/VDialog');

class TplTableItem{
  name=''
  value=''
  constructor(key,value){
    this.name=key
    this.value=value
  }
}
class TplTable{
  data=[]
  constructor(items, keys){
    for(let item in items){
      if (!!keys && keys.length) {
        let obj = {};
        keys.forEach(key => {
          if (items[item].hasOwnProperty(key)) {
            obj[key] = items[item][key]
          }
        });
        this.data.push(new TplTableItem(item,obj))
      }
      else {
        this.data.push(new TplTableItem(item,items[item]))
      }
    }
    
  }
}
export default {
  mixins: [LocalizationMixin, FiltersMixin, HidingMixin, UpdateDataMixin],
  components: {VDialog},
  data(){
    return{
      dialog:{
          state:false,
          item:null,
          mode:'update',
          form:{},
          validation:false
      },

      namePath:''
    }
  },
  props:{
    expandable:{
      type:Boolean,
      default:false
    },
    url:{
      type:String
    },
    keys: {
      type: Array
    }
  },
  computed:{
      getHeaders(){
          if(this.items.data)  {
              const data=this.items.data.sort((a,b)=>b.value.length - a.value.length)
              if(this.items.data[0]){
                return Object.keys(this.items.data[0].value)
              }
          }
          return []
      },
      screenWidth() {
          return this.$store.state.screen.width;
      },
      isMobile(){
          return this.screenWidth<breakpoints.md
      },
      isInvalid(){
        let invalid=false
        let inputs=this.$refs
        for(let key in inputs){
          let input=inputs[key][0]
          input.validate()
          if(input.$v.$invalid) invalid=true
        }
        return invalid
      }
  },
  created(){
    this.namePath=getNamePath(this.url)
  },
  methods:{
    addPercent,
    showModal(item,mode){
        this.dialog.state=true
        this.dialog.mode=mode
        if(mode=='add'){
          this.dialog.item={
            value:{
              name:'',
              ...item.value
            }
          },
          this.dialog.form={name:'',...item.value}
        }else{
          this.dialog.item=item
          this.dialog.form={...item.value}
        }
    },
    fetchData(){
      this.loading=true
      return DataApi.get(this.url)
      .then((data)=>{
        this.items=new TplTable(data, this.keys)
        this.loading=false
      })
    },
    edit(){
      if(!this.isInvalid){
        const {name}=this.dialog.item
        const {form}=this.dialog
        DataApi.update(this.url,{[name]:form})
        .then(()=>{
          this.dialog.state=false
        })
      }
    },
    deleteItem({name}){
      DataApi.delete(this.url,name)
    },
    addItem(){
      if(!this.isInvalid){
        const { form }=this.dialog;
        const params={};

        for(const key in form) {
          if(key!='name') {
            params[key]=form[key];
          }
        }

        DataApi.add(this.url, { [form.name]:params })
        .then(()=>{
          this.dialog.state=false
        })
      }
    },

    onClickSave() {
      if(this.dialog.mode=='add') {
        this.addItem();
      } else {
        this.edit();
      }
    },

    getValue(item, type){
      return !this.getComponentHidingValue(type) ? item.value[type] : '********'
    }
  },
}
</script>

<style lang="stylus" scoped>
@require '~@/assets/stylus/mixins/mixins';
@require '~@/assets/stylus/vars/variables';

.tpl-complex-table
  overflow-x:auto
  &__btn
    flexy(center,center)
    width 127px !important
    margin-bottom 24px
    +breakpoint(ms-and-down){
        width 100% !important
    }
  .icons-col
    .table__btn_icon
      margin-right 20px
</style>
