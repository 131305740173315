<template>
  <div class="base-file">
    <base-input
      v-model="nameFile"
      :name="name"
      :placeholder="placeholder"
      class="base-file__text"
      readonly
      suffixIcon="upload"
      @focus="openFileDialog"
    >
      <template v-slot:icon-suffix>
        <svg-icon name="upload"></svg-icon>
      </template>
    </base-input>
    <input
      ref="file"
      class="base-file__input"
      type="file"
      @change="handleFileUpload()"
    />
  </div>
</template>

<script>
  export default {
    props: {
      value: {
        type: File
      },
      placeholder: {
        type: String
      },
      name: {
        type: String
      }
    },
    data() {
      return {
        nameFile: ''
      }
    },
    methods: {
      openFileDialog() {
        this.$refs.file.click()
      },
      handleFileUpload() {
        const file = this.$refs.file.files[0]
        this.$emit('input', file )
        this.nameFile = file.name
      }
    },
    computed:{
      inputVal: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit("input", value);
        },
      }
    }
  }
</script>

<style lang="stylus" scoped>
.base-file
  &__input
    display:none
</style>
