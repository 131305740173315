<template>
  <div class="tpl-info">
    <div class="tpl-info__item" :class="{'tpl-info__tables': !isMobile, 'tpl-info__tables_mobile': isMobile}">
      <div
        class="table__wrapper tpl-info__table"
        v-for="(table,index) in getTables"
        :key="index"
      >
        <div class="tpl tpl-update">
          <h3 class="tpl__header">{{ translate(table.title) }}</h3>
            <table class="table tpl-info__table">
              <tr class="table__row tpl-info__table_row tpl-info__table_header" v-for="(value, name) in table.values" :key="name">
                <td class="table__item tpl-info__table_col1">{{translate(name)}}</td>
                <td class="table__item tpl-info__table_col2">{{value}}</td>
              </tr>
            </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import LocalizationMixin from '@/mixins/LocalizationMixin.js'
  import UpdateDataMixin from '@/mixins/UpdateDataMixin.js'
  import DataApi from '@/services/api/DataApi.js'
  import breakpoints from '@/utils/breakpoints.js'

  export default {
    mixins: [LocalizationMixin, UpdateDataMixin],
    data(){
      return{
        loading:false,
        items:{},
      }
    },
    props:{
      url:{
        type:String
      },
      tables:{
        type:Object
      }
    },
    computed:{
      getTables(){
          return this.convertDataForTable();
      },
      screenWidth() {
        return this.$store.state.screen.width;
      },
      isMobile(){
        return this.screenWidth<breakpoints.md
      },


    },
    methods:{
      fetchData(){
        this.loading=true;
        return DataApi.get(this.url)
        .then((data)=>{
          this.items=data;
          this.loading=false
        })
      },
      convertDataForTable() {
        let result=[]
        let array=Object.entries(this.items);
        let tablesInfo = Object.values(this.tables);
        let lenght=array.length;

        if (lenght) {
          tablesInfo.forEach((tableInfo) => {
            let table = array.filter((item) => {
              return item[0] === tableInfo.name
            })[0];

            let obj = {};
            if (table[1]) {
              obj.title = tableInfo.name;
              obj.values = {};
              tableInfo.keys.forEach((key) => {
                obj.values[key] = table[1][key]
              });
              result.push(obj)
            }
          });

          return result
        }
      },
    },
  }
</script>

<style lang="stylus" scoped>
  @require '~@/assets/stylus/mixins/mixins';
  @require '~@/assets/stylus/vars/variables';
  .tpl
    max-width: 100%!important
    padding-bottom 12px!important
    &__header
      margin-bottom 0px
  .table
    font-size: 12px;
    table-layout: fixed;
    &__item
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis

    &__row
      height 38px

  .table__wrapper
    width 48%

  .tpl-info
    &__tables
      flexy(flex-start,flex-start, wrap, row)

    &__table
      height 100%
      &:only-child
        width 100%
      &:nth-child(2n+1)
        padding-bottom:24px
        padding-right:24px
      &:nth-last-child(1),&:nth-last-child(2)
        padding-bottom:0px
      &:first-child
        +breakpoint(sm-and-down){
          margin-right 0px
        }

        &_col1,
        &_col2
          width 50%
          padding-right 12px

        &_col1
        text-align right
        &_col2
          text-align left



  /*mobile styles*/

  .tpl-info__tables_mobile
    flexy(center,flex-start, wrap, column)
    .tpl-info__table
      width 100%
      padding-bottom:24px
      padding-right:0px
      &:nth-last-child(1)
        padding-bottom:0px



</style>
