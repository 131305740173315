export default {
  computed: {
    getHiding() {
      return this.$store.getters['frontend/getHiding']
    },
    getParams() {
      return this.$route.params
    }
  },
  methods: {
    getComponentHidingValue(value) {
      const { menu, submenu } = this.getParams
      let isHidden

      if (this.getHiding[`${menu}-${submenu}-${value}`]) {
        isHidden = this.getHiding[`${menu}-${submenu}-${value}`]
      } else if(this.getHiding[`${menu}-*-${value}`]) {
        isHidden = this.getHiding[`${menu}-*-${value}`]
      } else if(this.getHiding[`${menu}-${value}`]) {
        isHidden = this.getHiding[`${menu}-${value}`]
      } else if(this.getHiding[`${submenu}-${value}`]) {
        isHidden = this.getHiding[`${submenu}-${value}`]
      } else if(this.getHiding[`${submenu}-*-${value}`]) {
        isHidden = this.getHiding[`${submenu}-*-${value}`]
      } else if(this.getHiding[`${value}`]) {
        isHidden = this.getHiding[`${value}`]
      }

      return isHidden
    }
  }
}
