<template>
  <div class="form-container">
    <div class="tpl">
      <h3 class="tpl__header">{{translate('importCertificates')}}</h3>
      <div class="tpl__body">
        <base-file
          class="tpl__input"
          :name="translate('fileCertificate')"
          :placeholder="translate('select-file')"
          @input="getFileCertificate"
        />
        <base-file
          class="tpl__input"
          :name="translate('filePersonalKey')"
          :placeholder="translate('select-file')"
          @input="getFilePersonalKey"
        />
        <div class="tpl__wrapper">
          <base-btn outlined class="tpl__btn">{{translate('cancel')}}</base-btn>
          <base-btn class="tpl__btn" @click="importCert">{{translate('import')}}</base-btn>
        </div>
      </div>
    </div>

    <div class="tpl">
      <h3 class="tpl__header">{{ translate('actions') }}</h3>
      <div class="tpl__body actions">
        <base-btn
          :spinner="spinner"
          @click="deleteCert()"
        >
          {{ translate('deleteCert') }}
        </base-btn>
      </div>
    </div>
  </div>

</template>

<script>
import DataApi from '@/services/api/DataApi.js'
import LocalizationMixin from '@/mixins/LocalizationMixin.js'
  export default {
    mixins: [LocalizationMixin],
    props:{
      url:{
        type:String
      }
    },
    data(){
      return{
        items:{},
        loading:false,
        spinner:false,
        fileCertificate: null,
        filePersonalKey: null
      }
    },
    mounted(){
      this.fetchData()
    },
    methods:{
      fetchData(){
        this.loading=true
        this.$store.commit('setLoading', true)
        DataApi.get(this.url)
        .then((data)=>{
          this.items=data
          this.loading=false
        }).finally(() => {
          this.$store.commit('setLoading', false)
        })
      },

      importCert() {
        var formData = new FormData();

        formData.append('public', this.fileCertificate);
        formData.append('private', this.filePersonalKey);

        const url = `${this.url}/user`;
        DataApi.post_formdata(url, formData)
      },
      getFileCertificate(val) {
        this.fileCertificate = val
      },
      getFilePersonalKey(val) {
        this.filePersonalKey = val
      },
      deleteCert() {
        this.spinner = true;

        DataApi.delete(this.url, 'user')
        .finally(()=>{
          this.spinner = false

        })
      }
    },
  }
</script>

<style lang="stylus" scoped>
  @require '~@/assets/stylus/components/tpl'

.form-container
  display flex
  gap 24px

.tpl
  margin-bottom 24px
  height fit-content
</style>
