import { render, staticRenderFns } from "./BaseSnackbar.vue?vue&type=template&id=acc812ce&scoped=true&"
import script from "./BaseSnackbar.vue?vue&type=script&lang=js&"
export * from "./BaseSnackbar.vue?vue&type=script&lang=js&"
import style0 from "./BaseSnackbar.vue?vue&type=style&index=0&id=acc812ce&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "acc812ce",
  null
  
)

export default component.exports