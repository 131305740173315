export default {
  computed: {
    getLanguages() {
      return this.$store.getters['localization/getLanguages']
    },
    getLocalization() {
      return this.$store.getters['localization/getLocalization']
    },
    getDataLocal() {
      return this.getLocalization ? this.getLocalization.data : []
    },
    getParams() {
      return this.$route.params
    },
  },
  beforeMount() {
    this.setDefaultLanguage()
  },
  methods: {
    setDefaultLanguage() {
      const languages = this.getLanguages
      let defaultLang = null
      if (!this.getLocalization.length) {
        for (const item in languages) {
          if (languages[item].default) {
            defaultLang = languages[item]
            defaultLang.lang = item
          }
        }
        if(!this.getLocalization) {
          this.$store.dispatch('localization/setLanguage',{ name: defaultLang.name, lang: defaultLang.lang })
        }
      }
    },
    translate(val) {
      const { menu, submenu } = this.getParams
      if (this.getDataLocal[`%${menu}-${submenu}-${val}%`]) {
        return this.getDataLocal[`%${menu}-${submenu}-${val}%`]
      } else if(this.getDataLocal[`%${menu}-*-${val}%`]) {
        return this.getDataLocal[`%${menu}-*-${val}%`]
      } else if(this.getDataLocal[`%${menu}-${val}%`]) {
        return this.getDataLocal[`%${menu}-${val}%`]
      } else if(this.getDataLocal[`%${val}%`]) {
        return this.getDataLocal[`%${val}%`]
      }
      return `%${val}%`
    }
  }
}
