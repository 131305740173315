<template>
  <div v-if="!loading" class="home">
    <component
      class="home__component"
      v-for="(item) in getTemplate"
      :expandable="item.expandable"
      :key="item.urlapi"
      :keys="item.keys"
      :url="item.urlapi"
      :title="item.title"
      :is="item.template"
      :tables="item.tables"
      :charts="item.charts"
      :ticks="item.ticks"
      :pagination="item.pagination"
      :isDescendingSort="item.isDescendingSort"
    ></component>
    <PageNotFound v-if="!getTemplate"></PageNotFound>
  </div>
</template>

<script>
import TplComplexTable from '@/components/project/TplComplexTable.vue'
import TplTable from '@/components/project/TplTable.vue'
import TplLuaEditor from '@/components/project/TplLuaEditor.vue'
import TplJsonEditor from '@/components/project/TplJsonEditor.vue'
import TplSysteminfo from '@/components/project/TplSysteminfo.vue'
import TplBasicTable from '@/components/project/TplBasicTable.vue'
import TplMultitables from '@/components/project/TplMultitables.vue'
import TplUpdate from '@/components/project/TplUpdate.vue'
import TplChart from '@/components/project/TplChart.vue'
import TplList from '@/components/project/TplList.vue'
import TplCertificates from '@/components/project/TplCertificates.vue'
import TplReboot from '@/components/project/TplReboot.vue'
import TplEmailSend from '@/components/project/tplEmailSend'
import TplScriptsList from '@/components/project/TplScriptsList'
import { addPercent } from '@/helpers/formatedData.js'
import PageNotFound from '@/views/PageNotFound.vue'

export default {
  components: {
    TplComplexTable,
    TplTable,
    TplLuaEditor,
    TplJsonEditor,
    TplSysteminfo,
    TplBasicTable,
    TplMultitables,
    TplList,
    TplChart,
    TplUpdate,
    TplCertificates,
    TplReboot,
    TplEmailSend,
    TplScriptsList,
    PageNotFound
  },
  name: 'PagesView',
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getSidebar() {
     return this.$store.getters['frontend/getTemplate'].sidebar
    },
    getSection() {
      let obj = this.getSidebar
      for (let key in obj) {
        if (obj[key].name === addPercent(this.getParams.menu)) {
          return obj[key]
        }
      }
    },
    getSubmenu() {
      if(this.getParams.submenu) {
        let obj = this.getSection.submenu
        for (let key in obj) {
          if (obj[key].name === addPercent(this.getParams.submenu)) {
            return obj[key]
          }
        }
      }
    },
    getTemplate() {
      if (this.getSubmenu) {
        return this.getSubmenu.data
      } else {
        return this.getSection.data
      }
    },
    getParams() {
      return this.$route.params
    }
  },
  updated() {
    this.$store.commit('setLoading', true);
  },
  created() {
    this.$store.commit('setLoading', true);
  },
}
</script>

<style  lang="stylus">
.home
  width 100%
  position relative
  flexy(center,center)
  &__component
    margin-bottom 50px
</style>
