<template>
  <base-dropdown
    v-model="isShow"
    class="lang-btn"
    :items="getLanguages"
  >
    <template v-slot:activate>
      <base-btn class="lang-btn__btn_item" text pr prefixIcon="lang">
        {{ getLocalization.name }}
      </base-btn>
    </template>
    <template v-slot:items>
      <li
        v-for="(item, idx) in getLanguages"
        :key="idx"
        class="base-menu__item"
        @click="changeLang(item.name, item.lang)"
      >
        {{ item.name }}
      </li>
    </template>
  </base-dropdown>
</template>

<script>
import LocalizationMixin from '@/mixins/LocalizationMixin.js'

export default {
  mixins: [LocalizationMixin],
  data() {
    return {
      isShow: false,
      toggleCard: false
    }
  },
  methods: {
    changeLang(name, lang) {
      this.$store.dispatch('localization/setLanguage',{ name, lang })
    }
  }
}
</script>

<style lang="stylus" scoped>
@require '~@/assets/stylus/mixins/mixins';
@require '~@/assets/stylus/vars/variables';
.lang-btn
    z-index 9999
    &__btn
        flexy(center,center)
        cursor pointer
        &_item
            font-size 16px !important

</style>
