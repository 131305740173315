<template>
  <div v-on-clickaway="hide" class="base-menu">
    <div @click="toogle" class="base-menu__activate">
      <slot name="activate"></slot>
    </div>
    <ul @click="hide" v-if="inputVal" class="base-menu__menu">
        <slot name="items">
          <li @click="setItem(item)" class="base-menu__item" v-for="item in items" :key="item" >
              {{item}}
          </li>
        </slot>
    </ul>
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway';
  export default {
    directives: {onClickaway: onClickaway},
    props:{
      value:{
        type:Boolean
      },
      items:{
        default:()=>[]
      },
    },
    methods:{
      show(){
          this.inputVal=true
      },
      hide(){
          this.inputVal=false
      },
      toogle(){
          this.inputVal=!this.inputVal
      },
      setItem(item){
        this.$emit('setItem',item)
      }
    },
    computed:{
        inputVal: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            }
        },
    },
    
  }
</script>

<style lang="stylus" scoped>
@require '~@/assets/stylus/mixins/mixins';
@require '~@/assets/stylus/vars/variables';
.base-menu
  position relative
  z-index 9997
  &__menu
    width 20.5%
    position:fixed
    background: #FFFFFF;
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.2);
    margin-top 1px
    max-height 120px
    overflow-y: auto
  &__item
    flexy(flex-start,center)
    height 40px
    padding 8px 24px
    cursor pointer
    &:hover
        background: rgba(53, 106, 201, 0.08);
        color:$theme-light.primary.base
</style>
