<template>
  <div
    v-if="inputVal"
    :class="{'base-menu_mobile':mobile}"
    class="base-menu card-light"
  >
    <div
      class="base-menu__btn"
      @click="inputVal=false"
    >
      <svg-icon name="close"></svg-icon>
    </div>
    <div>
      <svg-icon v-if="mobile" class="base-layout__logo" name="logo"></svg-icon>
      <h3 v-if="deviceInfo" class="base-menu__description" :class="{'base-menu__description--mobile': mobile}">
        <div>{{ deviceInfo.hostname }}</div>
        <div>{{ deviceInfo.location }}</div>
      </h3>
      <ul class="base-menu__menu">
        <base-menu-item
          v-for="item in items"
          :key="item.name"
          :currentKeyMenu="currentKeyMenu"
          :data='item'
          @select="select"
        ></base-menu-item>
      </ul>
    </div>
  </div>
</template>

<script>
import BaseMenuItem from './BaseMenuItem.vue'
import LangBtn from '@/components/LangBtn.vue'
import { addPercent } from '@/helpers/formatedData.js'
import DataApi from '@/services/api/DataApi.js'

export default {
  components:{ BaseMenuItem,LangBtn },
  data(){
    return{
      isShow: true
    }
  },
  props: {
    value: {
      type: Boolean
    },
    items: {
      type: Object
    },
    mobile: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    inputVal: {
      get() {
        if (!this.mobile) {
          return true
        }
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    currentKeyMenu(){
      return addPercent(this.$route.params.submenu)
    },
    deviceInfo() {
      return this.$store.getters['frontend/getDeviceInfo']
    }
  },
  methods:{
    select(selected) {
      this.$emit('select', selected)
    },
    toggle() {
      this.inputVal = !this.inputVal
    }
  }
}
</script>

<style lang="stylus" scoped>
@require '~@/assets/stylus/mixins/mixins';
@require '~@/assets/stylus/vars/variables';
@require '~@/assets/stylus/mixins/scroll';
scrollStyle()
.base-layout
  &__logo
    width 188px
    height 52px
    margin-right 100px
    +breakpoint(sm-and-down){
      margin 0px
      margin-left -12px
    }
.base-menu
  position:fixed
  height 100vh
  border-radius: 0px;
  padding 24px 12px
  overflow hidden
  &__btn
    cursor pointer
    position absolute
    right 20px
    top 20px
  &_mobile
    position fixed
    height 100vh
    z-index 9999
    top:0px
  &__description
    font-weight: 500;
    font-size: 16px;
    color: $theme-light.primary.base
    margin-top 70px
    margin-left 24px
    margin-bottom 20px
    &--mobile
      margin-top 20px
  &__menu
    height calc(100vh - 150px);
    overflow scroll
  &__menu,
  &__submenu
    padding 0px
    list-style none
    color $theme-light.third.base
  &__item
    flexy(space-between,center)
    width 100%
    padding 8px 0px
    padding-left 24px
    padding-right 16.87px
    cursor pointer
    &_expand
      width 100%
      font-weight: 400;
      font-size: 16px;
      color $theme-light.third.color1
  &__icon
    width 10.52px
    height 10.52x
</style>
