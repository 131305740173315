<template>
  <div class="tpl tpl-reboot">
    <h3 class="tpl__header">{{translate('reboot')}}</h3>
    <div class="tpl__body">
      <base-input
        :name="translate('rebootStartAfter')"
        :placeholder='`0 ${translate("sec")}`'
        v-model="reboot"
      >
        <template v-slot:icon-suffix>
          <svg-icon name='arrow-circle'/>
        </template>
      </base-input>
      <base-btn
        class="tpl__btn"
        :spinner="loading"
        @click="fireReboot()"
      >
        {{translate('reboot')}}
      </base-btn>
    </div>
  </div>
</template>

<script>
import DataApi from '@/services/api/DataApi.js'
import LocalizationMixin from '@/mixins/LocalizationMixin.js'
  export default {
    mixins: [LocalizationMixin],
    props:{
      url:{
        type:String
      }
    },
    data(){
      return{
        items:{},
        loading: false,
        reboot: "",
        spinner: false
      }
    },
    methods:{
      fireReboot(){
        this.loading=true;
        this.spinner=true;
        this.$store.commit('setLoading', true)
        var delay = this.reboot * 1000;
        DataApi.post(this.url, { delay: delay })
        .then((data)=>{
          this.items=data;
          this.loading=false
        }).finally(() => {
          this.$store.commit('setLoading', false);
          this.spinner=false
        })
      }
    },
  }
</script>
