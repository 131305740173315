<template>
  <div>
    <div class="tpl-info">
        <div class='tpl-info__item tpl-info__tables'>
          <table v-if="data.length" class="table tpl-info__table">
            <tr class="table__row table__row_header tpl-info__table_header">
              <th class="table__item tpl-info__table_col1">{{ translate('name') }}</th>
              <th class="table__item tpl-info__table_col2">{{ translate('value') }}</th>
            </tr>
            <tr v-if="data[0].label" class="table__row tpl-info__table_row">
              <td class="table__item tpl-info__table_col1">{{ translate('label') }}</td>
              <td class="table__item tpl-info__table_col2">{{ data[0].label }}</td>
            </tr>
            <tr class="table__row tpl-info__table_row">
              <td class="table__item tpl-info__table_col1">{{ translate('running') }}</td>
              <td class="table__item tpl-info__table_col2">{{ data[0].running ? true : false }}</td>
            </tr>
            <tr class="table__row tpl-info__table_row">
              <td class="table__item tpl-info__table_col1">{{ translate('boot') }}</td>
              <td class="table__item tpl-info__table_col2">{{ data[0].boot ? true : false }}</td>
            </tr>
            <tr class="table__row tpl-info__table_row">
              <td class="table__item tpl-info__table_col1">{{ translate('next_update') }}</td>
              <td class="table__item tpl-info__table_col2">{{ data[0].next_update ? true : false }}</td>
            </tr>
            <tr v-if="data[0].firmware.name" class="table__row tpl-info__table_row">
              <td class="table__item tpl-info__table_col1">{{ translate('name') }}</td>
              <td class="table__item tpl-info__table_col2">{{ data[0].firmware.name }}</td>
            </tr>
            <tr v-if="data[0].firmware.version" class="table__row tpl-info__table_row">
              <td class="table__item tpl-info__table_col1">{{ translate('version') }}</td>
              <td class="table__item tpl-info__table_col2">{{ data[0].firmware.version }}</td>
            </tr>
            <tr v-if="data[0].firmware.date" class="table__row tpl-info__table_row">
              <td class="table__item tpl-info__table_col1">{{ translate('date') }}</td>
              <td class="table__item tpl-info__table_col2">{{ data[0].firmware.date }}</td>
            </tr>
            <tr v-if="data[0].firmware.sha" class="table__row tpl-info__table_row">
              <td class="table__item tpl-info__table_col1">{{ translate('sha') }}</td>
              <td class="table__item tpl-info__table_col2">
                <div style="max-width: 100px; overflow: hidden; text-overflow: ellipsis;">
                  {{ data[0].firmware.sha }}
                </div>
              </td>
            </tr>
          </table>
          <table v-if="data.length && data[1]" class="table tpl-info__table">
            <tr class="table__row table__row_header tpl-info__table_header">
              <th class="table__item tpl-info__table_col1">{{ translate('name') }}</th>
              <th class="table__item tpl-info__table_col2">{{ translate('value') }}</th>
            </tr>
            <tr v-if="data[1].label" class="table__row tpl-info__table_row">
              <td class="table__item tpl-info__table_col1">{{ translate('label') }}</td>
              <td class="table__item tpl-info__table_col2">{{ data[1].label }}</td>
            </tr>
            <tr class="table__row tpl-info__table_row">
              <td class="table__item tpl-info__table_col1">{{ translate('running') }}</td>
              <td class="table__item tpl-info__table_col2">{{ data[1].running ? true : false }}</td>
            </tr>
            <tr class="table__row tpl-info__table_row">
              <td class="table__item tpl-info__table_col1">{{ translate('boot') }}</td>
              <td class="table__item tpl-info__table_col2">{{ data[1].boot ? true : false }}</td>
            </tr>
            <tr class="table__row tpl-info__table_row">
              <td class="table__item tpl-info__table_col1">{{ translate('next_update') }}</td>
              <td class="table__item tpl-info__table_col2">{{ data[1].next_update ? true : false }}</td>
            </tr>
            <tr v-if="data[1].firmware.name" class="table__row tpl-info__table_row">
              <td class="table__item tpl-info__table_col1">{{ translate('name') }}</td>
              <td class="table__item tpl-info__table_col2">{{ data[1].firmware.name }}</td>
            </tr>
            <tr v-if="data[1].firmware.version" class="table__row tpl-info__table_row">
              <td class="table__item tpl-info__table_col1">{{ translate('version') }}</td>
              <td class="table__item tpl-info__table_col2">{{ data[1].firmware.version }}</td>
            </tr>
            <tr v-if="data[1].firmware.date" class="table__row tpl-info__table_row">
              <td class="table__item tpl-info__table_col1">{{ translate('date') }}</td>
              <td class="table__item tpl-info__table_col2">{{ data[1].firmware.date }}</td>
            </tr>
            <tr v-if="data[1].firmware.sha" class="table__row tpl-info__table_row">
              <td class="table__item tpl-info__table_col1">{{ translate('sha') }}</td>
              <td class="table__item tpl-info__table_col2">
                <div style="max-width: 100px; overflow: hidden; text-overflow: ellipsis;">
                  {{ data[1].firmware.sha }}
                </div>
              </td>
            </tr>
          </table>
        </div>
    </div>
    <div class="form-container">
      <div class="tpl">
        <h3 class="tpl__header">{{ translate('updateCode') }}</h3>
        <div class="tpl__body">
          <base-file
            v-model="firmwareFile"
            class="tpl-update__input tpl__input"
            :name="translate('fileCode')"
            :placeholder="translate('select-file')"
          ></base-file>

          <base-btn
            class="tpl__btn"
            :spinner="spiners.updateCodeSpiner"
            @click="updateFirmware()"
          >
            {{ translate('updateCode') }}
          </base-btn>
        </div>
      </div>

      <div class="tpl">
        <h3 class="tpl__header">{{ translate('actions') }}</h3>
        <div class="tpl__body actions">
          <base-btn
            class="tpl__btn"
            :spinner="spiners.confirmCodeSpiner"
            @click="confirmFirmware()"
          >
            {{ translate('confirmCode') }}
          </base-btn>

          <base-btn
            class="tpl__btn"
            :spinner="spiners.rollbackCodeSpiner"
            @click="rollbackFirmware()"
          >
            {{ translate('rollbackCode') }}
          </base-btn>

          <base-btn
            class="tpl__btn"
            :spinner="spiners.boot_ota_0_spiner"
            @click="bootFirmware('ota_0')"
          >
            {{ translate('boot_ota_0_Code') }}
          </base-btn>
          <base-btn
            class="tpl__btn"
            :spinner="spiners.boot_ota_1_spiner"
            @click="bootFirmware('ota_1')"
          >
            {{ translate('boot_ota_1_Code') }}
          </base-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataApi from '@/services/api/DataApi.js'
import LocalizationMixin from '@/mixins/LocalizationMixin.js'

export default {
  mixins: [
    LocalizationMixin
  ],
  props: {
    url: {
      type: String
    }
  },
  data() {
    return {
      firmwareFile: null,
      items: {},
      data: [],
      loading: false,
      spiners: {
        updateCodeSpiner: false,
        confirmCodeSpiner: false,
        rollbackCodeSpiner: false,
        boot_ota_0_spiner: false,
        boot_ota_1_spiner: false,
      }
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      this.$store.commit('setLoading', true)
      DataApi.get(this.url)
        .then((data) => {
          this.data = data
          this.loading = false
        }).finally(() => {
        this.$store.commit('setLoading', false)
      })
    },
    updateFirmware() {
      this.spiners.updateCodeSpiner = true;
      const url = `${this.url}/upload`
      DataApi.post_binary(url, this.firmwareFile)
        .finally(() => {
          this.spiners.updateCodeSpiner = false;
        })
    },
    confirmFirmware() {
      this.spiners.confirmCodeSpiner = true;
      DataApi.post(this.url, { confirm: true })
      .finally(() => {
        this.spiners.confirmCodeSpiner = false;
      })
    },
    rollbackFirmware() {
      this.spiners.rollbackCodeSpiner = true;
      DataApi.post(this.url, { rollback: true })
      .finally(() => {
        this.spiners.rollbackCodeSpiner = false;
      })
    },
    bootFirmware(name) {
      this.spiners[`boot_${name}_spiner`] = true;
      DataApi.post(this.url, { boot: name })
      .finally(() => {
        this.spiners[`boot_${name}_spiner`] = false;
      })
    },
  }
}
</script>

<style lang="stylus" scoped>
@require '~@/assets/stylus/components/tpl'

.table
  font-size: 12px;
  &__row
    height 38px
    &_header
      background: #F3F2F7
  &__item
    padding 0px

.tpl-info
  width 100%
  &__tables
    flexy(center,flex-start)
  &__item
    margin-bottom 40px
  &__table
    height 100%
    &:first-child
      margin-right:24px
      +breakpoint(sm-and-down){
        margin-right 0px
      }
    &_col1
      width 50%
      text-align right
      padding-right 12px
    &_col2
      width 50%
      text-align left
      padding-left 12px

.form-container
  display flex
  gap 24px

.tpl-update
  max-width 100%
  margin-bottom 24px
  &__input
    margin-top 20px
.tpl__btn--mt10
  margin-top 10px

.actions
  .base-btn
    margin-top: 20px;

</style>
