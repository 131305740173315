<template>
  <div class="ui-kit">
    <div class="ui-kit__block">
      <h3>Элементы ввода</h3>
      <base-btn prefixIcon="plus">Добавить</base-btn>
      <base-btn prefixIcon="plus" outlined>Добавить</base-btn>
      <base-input/>
      <base-input error/>
      <base-input name="Логин" v-model="password" type='password' placeholder='Пароль'/>
    </div>
    <div class="ui-kit__block">
      <h3>Уведомления</h3>
      <base-snackbar type='info'/>
      <base-snackbar type='danger'/>
      <base-snackbar type='warning'/>
      <base-snackbar type='success'/>
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return{
        password:null
      }
    },
  }
</script>

<style lang="stylus" scoped>
@require '~@/assets/stylus/mixins/mixins';
@require '~@/assets/stylus/vars/variables';
.ui-kit
  padding 40px
  width 100%
  flexy(center,center,nowrap,column)

</style>