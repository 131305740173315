<template>
  <base-editor :url='url' type='json'/>
</template>

<script> 
export default {
  props:{
    url:{
      type:String
    }
  },
}
</script>
