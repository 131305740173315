const freqUpdateInMs = 1000

export default {
  data() {
    return {
      loading: false,
      items: {},
      timer: null
    }
  },
  mounted() {
    const fetchData = () => {
      this.fetchData()
      .finally(() => {
        this.timer = setTimeout(fetchData, freqUpdateInMs);
        this.$store.commit('setLoading', false);
      })
    }
    fetchData()
  },
  beforeDestroy() {
    clearTimeout(this.timer)
  }
}
