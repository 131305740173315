<template>
    <base-dropdown @setItem="setItem" v-model="isShow" :items="items">
        <template v-slot:activate>
            <base-input :plain="plain" :placeholder="placeholder" class="input-select__input" v-model="inputVal" readonly>
                <template v-slot:icon-suffix>
                    <svg-icon :class="{'input-select__icon_rotate':isShow}" class="input-select__icon" name='arrow'/>
                </template>
            </base-input>
        </template>
    </base-dropdown>
</template>

<script>

export default {
    props:{
        value: {
            default:null
        },
        items:{
            default:()=>[]
        },
        placeholder:{
            type:String,
            default:''
        },
        plain:{
            default:false
        }
    },
    data(){
        return {
            isShow:false
        }
    },
    computed:{
        inputVal: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            }
        },
    },
    methods:{
      setItem(item){
        this.inputVal=item
      },
    }
}
</script>

<style lang="stylus" scoped>
@require '~@/assets/stylus/mixins/mixins';
@require '~@/assets/stylus/vars/variables';
.input-select
    position:relative
    &__icon
        width 10px 
        height 10px 
        cursor pointer
        transform:rotate(180deg)
        transition transform 0.5s
        &_rotate
            transform:rotate(360deg)
</style>
