<template>
    <button :disabled="disabled" @click="click" :class="getClasses" class="base-btn">
      <svg-icon class="base-btn__icon" v-if="prefixIcon" :name='prefixIcon'/>
      <span class="base-btn__text">
        <base-spiner v-if='spinner' class="button-loader" :color="'#fff'"/>
        <slot>
        </slot>
      </span>
      <svg-icon class="base-btn__icon" v-if="suffixIcon" :name='suffixIcon'/>
    </button>
</template>

<script>

  export default {
    props:{
      prefixIcon:{
        type:String
      },
      suffixIcon:{
        type:String
      },
      outlined:{
        type:Boolean,
        default:false
      },
      text:{
        type:Boolean,
        default:false
      },
      plain:{
        type:Boolean,
        default:false
      },
      disabled:{
        type:Boolean,
        default:false
      },
      spinner:{
        type:Boolean,
        default:false
      },

      theme: {
        type: String,
        default: 'custom',
      }
    },
    computed:{
      getClasses(){
        return [
          [`theme_${this.theme}`],
          {
            'plain': this.plain,
            'outlined': this.outlined,
            'text': this.text,
            'disabled': this.disabled,
          },
        ]
      }
    },
    methods:{
      click(){
        this.$emit('click')
      }
    }
  }
</script>

<style lang="stylus" scoped>
@require '~@/assets/stylus/mixins/mixins';
@require '~@/assets/stylus/vars/variables';
.base-btn
  flexy(center,center)
  height 36px
  border none
  cursor pointer
  border-radius: 5px;
  background $theme-light.primary.base
  color $theme-light.secondary.base
  box-shadow: 0px 0px 6px rgba(53, 106, 201, 0.6);
  padding-top 10.5px
  padding-bottom 10.5px
  font-weight: 400;
  font-size: 14px;
  font-family inherit
  width 100%
  transition all 0.5s;
  &__text
    display: flex
  &__icon
    width 14.67px
    height 14.67px
    margin-right 11.67px
  &:hover
    background: darken($theme-light.primary.base, 10%);

  .button-loader
    width: 18px!important;
    height: 18px!important;
    position: relative!important;
    padding: 0;
    margin-right 10px
    overflow-y inherit

.outlined
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border: 1px solid $theme-light.primary.base;
  background:none
  color $theme-light.primary.base;
  &:hover
    background: rgba(53, 106, 201, 0.04);

.text
  box-shadow none
  border none
  background:none
  color $theme-light.primary.base;
  &:hover
    background: rgba(53, 106, 201, 0.04);
  .base-btn
    &__icon
      margin-right 11.67px
      width 24px
      height 24px

.plain
  border none
  background none
  box-shadow: none
  color:$theme-light.primary.base;

.disabled
  cursor auto
  background: gray;
  &:hover
    background: gray;
  opacity 0.8

.theme
  &_red
    background: $theme-light.danger.base
    &:hover
      background: darken($theme-light.danger.base, 10%);

</style>
