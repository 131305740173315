<template>
  <div class="editor">
    <div class="editor__header">
      <div class="editor__wrapper">
        <base-btn v-if="type !== 'lua'" class="editor__btn">
          {{ translate('add_file') }}
        </base-btn>
        <input-select
          v-if="scripts"
          v-model="selected"
          class="editor__select"
          :placeholder="translate('select_script')"
          plain
          :items='Object.keys(scripts)'
        ></input-select>
        <base-btn
          v-if="type === 'lua'"
          class="editor__btn"
          @click="saveScript"
        >
          {{ translate('save') }}
        </base-btn>
      </div>
      <div v-if="type !== 'lua'" class="editor__wrapper">
        <base-btn
          class="editor__btn_plain"
          plain
          prefixIcon="download"
          @click="download"
        >
          {{ translate('export') }}
        </base-btn>
        <base-btn
          class="editor__btn_plain"
          plain
          prefixIcon="upload"
          @click="upload"
        >
          {{ translate('import') }}
        </base-btn>
      </div>
    </div>
    <div id="editor"></div>
    <base-btn v-if="isMobile" outlined class="editor__btn_full">
      {{ translate('save') }}
    </base-btn>
  </div>
</template>

<script>
import DataApi from '@/services/api/DataApi.js'
import LocalizationMixin from '@/mixins/LocalizationMixin.js'
import { readFile, createFile } from '@/helpers/files'
import ace from 'brace'
import 'brace/mode/lua'
import 'brace/mode/json'
import 'brace/theme/iplastic'
import breakpoints from '@/utils/breakpoints.js'

export default {
  mixins: [LocalizationMixin],
  props: {
    url: {
      type: String
    },
    type: {
      type: String,
      default: 'json'
    },
    scripts: {
      type: Object
    },
    selectedScript: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selected: null,
      code: '',
      editor: null
    }
  },
  computed: {
    screenWidth() {
      return this.$store.state.screen.width
    },
    screenHeight() {
      return this.$store.state.screen.height
    },
    isMobile() {
      return this.screenWidth < breakpoints.sm
    }
  },
  watch: {
    selectedScript() {
      this.selected = this.selectedScript
    },
    selected(value) {
      DataApi.get(`/api/v1/scripts/${value}`)
        .then((data) => {
          this.code = data
        })
    },
    code(value) {
      this.editor.setValue(value)
    }
  },
  mounted() {
    if (this.selectedScript !== '') {
      this.selected = this.selectedScript
    }
    if (!this.scripts) {
      this.fetchData()
    }
    this.editor = ace.edit("editor")
    this.editor.setOptions({
      mode: `ace/mode/${this.type}`,
      theme: 'ace/theme/iplastic',
      minLines: 5,
      //maxLines: 5,
      fontSize: '16px',
      wrap: true,
      useSoftTabs: true,
      tabSize: 2,
    })

    setTimeout(() => {
      this.setEditorHeight()
    }, 1000)
  },
  created() {
    window.addEventListener("resize", this.onWindowResize);

  },
  destroyed() {
    window.removeEventListener("resize", this.onWindowResize);
  },
  methods:{
    setEditorHeight() {
      const baseLayoutHeader = document.getElementsByClassName('base-layout__header');
      const editorHeader = document.getElementsByClassName('editor__header');
      const editorLine = document.getElementsByClassName('ace_line_group');

      const baseLayoutHeaderHeight = baseLayoutHeader[0].offsetHeight;
      const editorHeaderHeight = editorHeader[0].offsetHeight;
      const editorLineHeight = editorLine[0].offsetHeight;


      const editorLinesCount = (this.screenHeight - baseLayoutHeaderHeight - editorHeaderHeight) / editorLineHeight;
      this.editor.setOptions({
        maxLines: Math.floor(editorLinesCount),
      });
      this.editor.resize();
    },
    fetchData() {
      this.loading = true
      this.$store.commit('setLoading', true);
      DataApi.get(this.url)
        .then((data) => {
          this.code = JSON.stringify(data, null, 2)
          this.loading = false
        }).finally(() => {
        this.$store.commit('setLoading', false)
      })
    },
    upload() {
      readFile()
      .then((item) => this.code = item)
    },
    download() {
      createFile(this.code,this.selected ? this.selected : 'main.lua')
    },
    saveScript() {
      const code = this.editor.getValue()
      DataApi.post_plain(`/api/v1/scripts/${this.selected}`, code)
    },
    onWindowResize() {
      setTimeout(() => {
        this.setEditorHeight()
      }, 1000)
    },

  }
}
</script>

<style lang="stylus" scoped>
@require '~@/assets/stylus/mixins/mixins';
@require '~@/assets/stylus/vars/variables';
.editor
  width calc(100%+50px)
  position absolute
  top -30px
  left: -30px
  &__header
    flexy(space-between,center,wrap)
    width 100%
    background: #F3F2F7;
    padding 7.5px 12px
    +breakpoint(sm-and-down){
      padding 24px
      background white
      margin-top 35px
    }
  &__wrapper
    flexy(flex-start,center)
    &:last-child
      +breakpoint(ms-and-down){
        margin-top 20px
      }
  &__btn
    width 135px !important
    &_plain
      width 100px !important
    &_full
      margin 20px
  &__select
    width 170px
    margin-left 10px
</style>
