import Api from '@/services/api/BaseApiService'

export default class AuthApi extends Api {
  static login(params) {
    return new Promise((resolve, reject) => {
      this.apiService.post(`api/v1/login`, params,{
          headers: {
            'Content-Type': 'text/plain'
          }
        })
        .then(({ data }) =>
          resolve(data)
        )
        .catch((error) => reject(error))
    })
  }
}
