import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthPage from '../views/AuthPage.vue'
import BaseLayout from '../layouts/BaseLayout.vue'
import PagesView from '../views/PagesView.vue'
import UIkit from '../views/UIkit.vue'
import PageNotFound from '../views/PageNotFound.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'layout',
    meta: { requiresAuth: true },
    component: BaseLayout,
    redirect: 'menu/info/systeminfo',
    children: [
      {
        path: '/menu/:menu/:submenu?',
        name: 'menu',
        component: PagesView
      },
    ]
  },
  {
    path: '/auth',
    name: 'auth',
    component: AuthPage
  },
  {
    path: "/uikit",
    name: 'uikit',
    component: UIkit
  },
  { path: "*", component: PageNotFound }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name === 'auth' && store.getters["auth/isAuth"]) {
    next('menu/info/systeminfo')
  }
  if (to.matched.some(record => record.meta.requiresAuth) && !store.getters['auth/isAuth']) {
    next('/auth')
  }
  next()
})

export default router
