<template>
  <md-menu :md-active.sync="toggleCard" md-size="medium" md-align-trigger class="profile-btn">
        <div class="profile-btn__btn">
            <md-icon :md-src="require('@/assets/icons/account-circle.svg')" />
            <md-button md-menu-trigger class="profile-btn__btn">
                <p >{{getUser.name}}</p>
            </md-button>
        </div>
        <md-menu-content>
            <md-button @click="logOut()" >{{translate('logout')}}</md-button>
        </md-menu-content>
  </md-menu>
</template>

<script>
import LocalizationMixin from '@/mixins/LocalizationMixin.js'

export default {
    mixins: [LocalizationMixin],
    data(){
        return{
            toggleCard:false
        }
    },
    methods:{
        toggle () {
            this.toggleCard = !this.toggleCard
        },
        logOut(){
            this.$store.dispatch('auth/logout')
            this.toggle()
        }
    },
    computed:{
        getUser(){
            return this.$store.getters['auth/getUser']
        },
    }
}
</script>

<style lang="stylus" scoped>
@require '~@/assets/stylus/mixins/mixins';
@require '~@/assets/stylus/vars/variables';
    .profile-btn
        &__btn
            flexy(center,center)
</style>