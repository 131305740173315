export function readFile(){
    return new Promise((resolve, reject)=>{
    var input = document.createElement('input');
    input.type = 'file';
    
    input.onchange = e => { 
       var file = e.target.files[0];
       let reader = new FileReader();
       reader.readAsText(file,"utf-8");
       
       reader.onload = function() {
        resolve(reader.result);
      };
    
      reader.onerror = function() {
        reject(reader.error);
      };
    }
    
    input.click();
    })
}

export function createFile(str,name){
    let blob = new Blob([str], {type: "text/plain"})
    let link = document.createElement("a")
    link.setAttribute("href", URL.createObjectURL(blob))
    link.setAttribute("download", name)
    link.click()
}