<template>
  <div class="tpl tpl-update">
    <h3 class="tpl__header">{{ translate('sendEmail') }}</h3>
    <form novalidate @submit.prevent="validateEmail">
      <div class="tpl__body">
        <base-input
          v-model.trim="$v.email.$model"
          class="sendEmail__input"
          :error="showError && getErrorEmail"
          :name="translate('inputEmail')"
          :placeholder="translate('inputEmail')"
        ></base-input>
        <base-input
          v-model.trim="$v.subject.$model"
          class="sendEmail__input"
          :error="showError && getErrorSubject"
          :name="translate('inputSubject')"
          :placeholder="translate('inputSubject')"
        ></base-input>
        <base-textarea
          v-model.trim="$v.text.$model"
          :error="showError && getErrorText"
          :name="translate('inputText')"
          :placeholder="translate('inputText')"
        ></base-textarea>
        <base-btn class="tpl__btn" @click="validateEmail" :disabled="loading">
          {{ translate('sendEmail') }}
        </base-btn>
      </div>
    </form>
  </div>
</template>

<script>
import DataApi from '@/services/api/DataApi.js'
import LocalizationMixin from '@/mixins/LocalizationMixin.js'
import BaseTextarea from "@/components/input/BaseTextarea.vue"
import { required, email } from "vuelidate/lib/validators"
import { validationMixin } from "vuelidate"

export default {
  mixins: [
    validationMixin,
    LocalizationMixin
  ],
  components: {
    BaseTextarea
  },
  props: {
    url: {
      type: String
    }
  },
  validations: {
    email: {
      required,
      email,
    },
    subject: {
      required,
    },
    text: {
      required,
    }
  },
  data() {
    return {
      showError: false,
      items: {},
      loading: false,
      email: '',
      subject: '',
      text: ''
    }
  },
  computed: {
    getErrorEmail() {
      return this.$v.$invalid ? !this.$v.email.required || !this.$v.email.email : false
    },
    getErrorSubject() {
      return this.$v.$invalid ? !this.$v.subject.required : false
    },
    getErrorText() {
      return this.$v.$invalid ? !this.$v.text.required : false
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    validateEmail() {
      this.$v.$touch()
      this.showError = true
      console.log(this.$v)
      if (!this.$v.$invalid) {
        this.loading = true
        this.sendEmail()
      }
    },
    sendEmail() {
      const email = {
        to: this.email,
        subject: this.subject,
        body: this.text
      }
      DataApi.post(this.url, email)
        .finally(() => {
          this.loading = false
        })
    },
    fetchData() {
      this.loading = true
      DataApi.get(this.url)
      .then((data)=> {
        this.items = data
        this.loading = false
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.sendEmail
  &__input
    margin-bottom 36px
</style>
