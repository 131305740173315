import store from '../index'
import ruRU from '@/assets/languages/ru-RU.json'
import enEN from '@/assets/languages/en-EN.json'

export default {
  namespaced: true,
  state: () => ({
    language: null,
  }),
  getters: {
    getLocalization(state) {
      return state.language
    },
    getLanguages() {
      return store.getters['frontend/getTemplate'] ? store.getters['frontend/getTemplate'].languages : []
    },
    getLocaleBrowser() {
      return new Intl.Locale(window.navigator.language).language
    }
  },
  mutations: {
    setLang(state, { params }) {
      state.language = params
    },
  },
  actions: {
    setLanguage({ commit }, { name, lang }) {
      if (lang === 'ru-RU') {
        commit('setLang', { params: { name: 'Русский', data: ruRU['phrases'] } }) //FIXME
      }
      if (lang === 'en-EN') {
        commit('setLang', { params: { name: 'English', data: enEN['phrases'] } }) //FIXME
      }
    },
    changeLang({ commit }, index) {
      commit('setLocal', index)
    }
  }
}
