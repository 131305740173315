import Api from '@/services/api/BaseApiService'

export default class DataApi extends Api {
  static get(url) {
    return new Promise((resolve, reject) => {
      this.apiService
        .get(url)
        .then(({ data }) =>
          resolve(data)
        )
        .catch((error) => reject(error))
    });
  }

  static post(url, params) {
    return new Promise((resolve, reject) => {
      this.apiService
        .post(url, params)
        .then(({ data }) =>
          resolve(data)
        )
        .catch((error) => reject(error))
    })
  }

  static post_plain(url, params) {
    const headers = { 'Content-Type': 'text/plain' }
    return new Promise((resolve, reject) => {
      this.apiService
        .request({ url, method: 'POST', data: params, headers: {...headers}})
        .then(({ data }) =>
          resolve(data)
        )
        .catch((error) => reject(error))
    })
  }

  static post_formdata(url, params) {
    const headers = { 'Content-Type': 'multipart/form-data' }
    return new Promise((resolve, reject) => {
      this.apiService
      .request({ url, method: 'POST', data: params, headers: {...headers}})
      .then(({ data }) =>
        resolve(data)
      )
      .catch((error) => reject(error))
    })
  }

  static post_binary(url, data) {
    return new Promise((resolve, reject) => {
      this.apiService
        .request({ url, method: 'POST', data})
        .then(({ data }) =>
          resolve(data)
        )
        .catch((error) => reject(error))
    })
  }

  static update(url, params) {
    return new Promise((resolve, reject) => {
      this.apiService
        .post(url, params)
        .then(({ data }) =>
          resolve(data)
        )
        .catch((error) => reject(error));
    });
  }

  static delete(url, name) {
    return new Promise((resolve, reject) => {
      this.apiService
        .delete(`${url}/${name}`)
        .then(({ data }) =>
          resolve(data)
        )
        .catch((error) => reject(error))
    });
  }

  static add(url, params) {
    return new Promise((resolve, reject) => {
      this.apiService
        .post(url, params)
        .then(({ data }) =>
          resolve(data)
        )
        .catch((error) => reject(error));
    })
  }
}
