export function globalResponseProcessing (response, store) {
  if(response.result==="ok") {
    let msg = 'Success';
    if (response.message) {
      msg = `${msg}: ${response.message}`
    }
    store.dispatch('showSnackbar', {text: msg, type: 'success', timeout:7000})
  }
  else if(response.result==="error") {
    let msg = 'Error';
    if (response.message) {
      msg = `${msg}: ${response.message}`
    }
    store.dispatch('showSnackbar',{text:msg,type:'danger',timeout:7000})
  }
  else {
    let msg = 'Warning';
    if (response.message) {
      msg = `${msg}: ${response.message}`
    }
    store.dispatch('showSnackbar',{text:msg,type:'warning',timeout:7000})
  }
}

export function catchResponseProcessing (error, store) {
  let msg = 'Error';
  if (error.message) {
    msg = `${msg}: ${error.message}`
  }
  store.dispatch('showSnackbar',{text: msg,type:'danger',timeout:7000})
}
