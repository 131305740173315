<template>
      <li class="menu-item" :class="{'menu-item_expand':data.submenu}">
        <div @click="clickItem(!!data.submenu,data.name)" :class="{'menu-item__item_hoverable' :!data.submenu}" class="menu-item__item">
          <div class="menu-item__text">
            <svg-icon class="menu-item__icon" v-if="!data.submenu" :name='deletePercent(data.name)'/>
            {{translate(deletePercent(data.name))}}
          </div>
          <svg-icon :class="{'menu-item__icon_rotate':isShow}" class="menu-item__icon_expand" v-if="data.submenu" name='arrow'/>
        </div>
        <transition name="expand">
          <ul v-if="isShow" class="menu-item__submenu">
            <li 
              @click.stop="clickItem(false,data.name,item.name)" 
              class="menu-item__item menu-item__item_hoverable" 
              :class="{'menu-item__item_activated':item.name==currentKeyMenu}"
              v-for="item in data.submenu"
            >
              <div class="menu-item__text">
                <svg-icon class="menu-item__icon" :name='deletePercent(item.name)'/>
                {{translate(deletePercent(item.name))}}
              </div>
            </li>
          </ul>
        </transition>
      </li>
</template>

<script>
import LocalizationMixin from '@/mixins/LocalizationMixin.js'
import {deletePercent,addPercent} from '@/helpers/formatedData.js'

export default {
  mixins: [LocalizationMixin],
  data(){
    return{
      isShow:false,
    }
  },
  props:{
    data:{
      type:Object
    },
    currentKeyMenu:{
      type:String
    }
  },
  methods:{
    addPercent,deletePercent,
    toogleShow(){
      this.isShow=!this.isShow
    },
    clickItem(isExpand,menu,submenu){
      if(isExpand) this.toogleShow()
      else this.$emit('select',{menu,submenu})
    }
  }
}
</script>

<style lang="stylus" scoped>
@require '~@/assets/stylus/mixins/mixins';
@require '~@/assets/stylus/vars/variables';
@require '~@/assets/stylus/animation/animations';

setAnimationOnComponent('expand',slide-in-top,'enter',0.1s)
setAnimationOnComponent('expand',slide-out-top,'leave',0.1s)

active()
  background $theme-light.primary.base
  box-shadow: 0px 0px 6px rgba(53, 106, 201, 0.6);
  color:$theme-light.secondary.base
  .menu-item__icon
    color:$theme-light.secondary.base

.menu-item
  margin-bottom 20px
  &__submenu
    padding 0px
    list-style none
    color $theme-light.third.base
  &__text
    flexy(center,center)
  &__item
    flexy(space-between,center)
    width 100%
    padding 8px 0px
    padding-left 24px
    padding-right 16.87px
    cursor pointer
    &_hoverable
      border-radius: 5px;
      transition all 0.1s
      &:hover
        active()
    &_activated
      active()
  &_expand
    width 100%
    font-weight: 400;
    font-size: 16px;
    color $theme-light.third.color1
  &__icon
    color $theme-light.third.color1
    width 18px
    height 18px
    margin-right 12px
    &_expand
      width 10.52px
      height 10.52x
      transform:rotate(180deg)
      transition 0.5s
    &_rotate
      transform:rotate(0deg)
</style>