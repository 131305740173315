<template>
  <div class="breadcumbs">
      <div :class="{'breadcumbs__disabled':!items.submenu}" v-if="items.menu" class="breadcumbs__wrapper">
        <div class="breadcumbs__item">{{translate(items.menu)}}</div>
        <div v-if="items.menu && items.submenu" class="breadcumbs__separator">/</div>
      </div>
      <div v-if='items.submenu'  class="breadcumbs__wrapper">
        <div class="breadcumbs__item breadcumbs__disabled">{{translate(items.submenu)}}</div>
      </div>
  </div>
</template>

<script>
import {addPercent} from '@/helpers/formatedData.js'
import LocalizationMixin from '@/mixins/LocalizationMixin.js'

export default {
    mixins: [LocalizationMixin],
    props:{
        items:{
            default:{}
        }
    },
    methods:{
        addPercent,
    },
}
</script>

<style lang="stylus" scoped>
@require '~@/assets/stylus/mixins/mixins';
@require '~@/assets/stylus/vars/variables';
.breadcumbs
    flexy(flex-start,center)
    &__wrapper
        flexy(center,center)
    &__item
        font-size 18px
        font-weight: 500;
        color:$theme-light.third.color1
        cursor default
    &__disabled
        font-weight bold
        color:$theme-light.third.base
    &__separator
        margin:0px 10px
        color:$theme-light.third.color1
</style>
