<template>
  <div class="loader__wrapper">
    <svg class="loader" width="57" height="59" viewBox="0 0 57 59" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M26.2495 36.6729C32.2339 39.6241 36.5054 45.191 37.8075 51.7351L16.7567 55.9224L26.2495 36.6729Z" :fill="color" :stroke="color"/>
      <path d="M21.2411 28.5141C20.2836 35.1178 16.3093 40.9006 10.4873 44.1609L0 25.4342L21.2411 28.5141Z" :fill="color" :stroke="color"/>
      <path d="M27.4538 21.2304C20.8776 22.3604 14.1495 20.3675 9.24976 15.8382L23.8191 0.0772705L27.4538 21.2304Z" :fill="color" :stroke="color"/>
      <path d="M36.3015 24.8881C33.1946 18.983 33.011 11.9685 35.8045 5.90881L55.2962 14.8947L36.3015 24.8881Z" :fill="color" :stroke="color"/>
      <path d="M35.5574 34.4313C40.2136 29.6516 46.8276 27.3095 53.4542 28.0938L50.9315 49.4084L35.5574 34.4313Z" :fill="color" :stroke="color"/>
    </svg>
  </div>
</template>

<script>
  export default {
    props: {
      color:{
        type:String,
        default: '#1B6CD0'
      }
    }
  }
</script>


<style lang="stylus" scoped>
  @require '~@/assets/stylus/mixins/mixins';
  @require '~@/assets/stylus/vars/variables';
  .loader
    &__wrapper
      z-index 99999
      backdrop-filter: blur(5px);
      position:relative
      overflow-y: hidden;
      flexy(center,center)
  .loader,
  .loader:after {
    overflow:hidden;
    border-radius: 25%;
    width:inherit
  }
  .loader {
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 10s infinite linear;
    animation: load8 5s infinite linear;
  }
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
</style>
