<template>
  <div
    v-on-clickaway="unfocus"
    :class="getClass"
    class="base-input"
  >
    <p class="base-input__name" v-if="name">{{ name }}</p>
    <div @click="focus" class="base-input__wrapper">
      <slot name="icon-prefix"></slot>
        <textarea
          v-model='inputVal'
          ref="input"
          class="base-input__input"
          :placeholder="placeholder"
          :disabled="disabled"
          :readonly="readonly"
          rows="5"
        ></textarea>
    </div>
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway'

export default {
  directives: {
    onClickaway: onClickaway
  },
  props: {
    name: {
      type: String
    },
    value: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    readonly: {
      type: Boolean
    },
    placeholder: {
      type: String
    },
    error: {
      type: Boolean
    },
    plain: {
      type: Boolean
    }
  },
  data() {
    return {
      focused: false,
      visible: false
    }
  },
  computed: {
    getClass() {
      return [
        this.readonly ? 'readonly' : '',
        this.plain ? 'plain' : '',
        this.disabled ? 'disabled' : '',
        (this.focused && !this.disabled) ? 'focused' : '',
        (this.error && !this.disabled) ? 'error' : ''
      ]
    },
    inputVal: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    }
  },
  methods: {
    focus() {
      this.$emit('focus')
      this.focused = true
      this.$refs.input.focus()
    },
    unfocus() {
      this.focused = false
      this.$refs.input.blur()
      this.$emit('unfocus')
    }
  }
}
</script>

<style lang="stylus" scoped>
@require '~@/assets/stylus/mixins/mixins';
@require '~@/assets/stylus/vars/variables';

$name='base-input'
.{$name}
  width 100%
  font-size 16px
  &__name
    font-weight: 400;
    font-size: 14px;
    margin 0px
    margin-bottom 6px
    color:$theme-light.primary.dark
  &__input
    outline none
    border none
    width 100%
    color currentColor
    resize: none
    background transparent
    font-size 18px
    height 100%
    &::placeholder
      color $theme-light.third.color2
  &__placeholder
    position:absolute
  &__suffix
    flexy(center,center)
    size(24px,24px)
  &__wrapper
    flexy(space-between,center)
    border: 1px solid $theme-light.third.color2
    border-radius: 2px;
    height 100%
    padding 0px 12px
    cursor text
    transition all 0.5s
  &__icon
    transition all 0.7s
    cursor pointer
    color $theme-light.third.color2
.disabled
  .{$name}
    &__wrapper,
    &__icon_clicable
      cursor default
.focused
  .{$name}
    &__wrapper
      border-color:$theme-light.primary.base
.error
  .{$name}
    &__wrapper
      border-color:$theme-light.danger.base
.plain
  .{$name}
    &__input
      &::placeholder
        color:$theme-light.primary.base
    &__wrapper
      border:none
      color:$theme-light.primary.base
.readonly
  .{$name}
    &__input
      cursor pointer


</style>
