<template>
  <div class="tpl-info">
    <div
      :class="{'tpl-info__charts': !isMobile, 'tpl-info__charts_mobile': isMobile}"
    >
        <!--<line-chart-->
          <!--class="tpl-info__chart"-->
          <!--v-for="(chart, index) in getCharts"-->
          <!--:key="index"-->
          <!--:ref="chart.ref"-->
          <!--:numbers='300'-->
        <!--/>-->

      <area-chart
        class="tpl-info__chart"
        v-for="(chart, index) in getCharts"
        :key="index"
        :ref="chart.ref"
        :chart="chart"
        :maxTicksLimitX='ticks.maxTicksLimitX'
        :maxTicksLimitY='ticks.maxTicksLimitY'
        :displayXLabels='ticks.displayXLabels'
        :title='chart.title'
      />


    </div>
  </div>
</template>

<script>
  import {addPercent} from '@/helpers/formatedData.js'
  import LocalizationMixin from '@/mixins/LocalizationMixin.js'
  import UpdateDataMixin from '@/mixins/UpdateDataMixin.js'
  import DataApi from '@/services/api/DataApi.js'
  import breakpoints from '@/utils/breakpoints.js'
  import LineChart from '../LineChart.vue'
  import AreaChart from '../AreaChart.vue'

  export default {
    mixins: [LocalizationMixin, UpdateDataMixin],
    components:{LineChart, AreaChart},
    data(){
      return{
        loading:false,
        items:{},
      }
    },
    props:{
      url:{
        type:String
      },
      charts:{
        type:Object
      },
      ticks:{
        type:Object
      }
    },
    computed:{
      getCharts(){
        return this.convertData();
      },
      screenWidth() {
        return this.$store.state.screen.width;
      },
      isMobile(){
        return this.screenWidth<breakpoints.md
      }
    },
    methods:{
      addPercent,
      fetchData(){
        this.loading=true
        return DataApi.get(this.url)
        .then((data)=>{
          this.items=data;
          this.calcLoad();
          this.loading=false
        })
      },
      convertData() {
        let result=[]
        let tablesInfo = Object.values(this.charts);

        tablesInfo.forEach((tableInfo, index) => {
          let obj = {};

          tableInfo.keys.forEach((key) => {
            obj[key] = this.items[key]
          });
          obj.ref = 'chart_' + index;
          obj.title = tableInfo.title;
          result.push(obj)
        });

        return result
      },
      getCurrentTime(){
        let now = new Date();
        let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

        var options = {
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          timeZone: timeZone
        };
        return now.toLocaleString("ru", options)
      },
      
      convertChartData(chart) {
        var arr = [];
        var chartEntity = Object.entries(chart);

        chartEntity.forEach((item) => {
          var obj = {
            name: item[0],
            y: item[1]
          };

          if (item[0] !== 'ref') {
            arr.push(obj)
          }
        });

        return arr
      },

      calcLoad(){
        var refs = this.$refs, me = this;
        
        this.getCharts.forEach(function(chart) {
          refs[chart.ref][0]?.update(me.getCurrentTime(), me.convertChartData(chart))
        })
      }
    },
  }
</script>

<style lang="stylus" scoped>
  @require '~@/assets/stylus/mixins/mixins';
  @require '~@/assets/stylus/vars/variables';
  .tpl
    max-width: 100%!important
    padding 0px 24px

  .line-chart
    width 49%
    padding-bottom:0px
    margin-bottom:24px
    border-radius: 12px;

  .tpl-info
    &__charts
      flexy(space-between,flex-start, wrap, row)
      gap 12px
    &__chart
      &:nth-child(2n+1)
        width 49%
      &:nth-last-child(1),&:nth-last-child(2)
        margin-bottom:0px
      &:first-child
        +breakpoint(sm-and-down){
          margin-right 0px
        }



  /*mobile styles*/

  .tpl-info__charts_mobile
    flexy(center,flex-start, wrap, column)
    .tpl-info__chart
      width 100%
      padding-bottom:24px
      margin-bottom: 24px
      padding-right:0px
      &:nth-last-child(1)
        padding-bottom:0px

</style>
