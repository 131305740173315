<template>
  <div class="page-not-found">
    <h3 class="page-not-found__header">404</h3>
    <p class="page-not-found__text">{{ translate('pageNotFound') }}</p>
    <base-btn
      class="page-not-found__btn"
      @click="redirectMain"
    >
      {{ translate('redirectMain') }}
    </base-btn>
  </div>
</template>

<script>
import LocalizationMixin from '@/mixins/LocalizationMixin.js'
export default {
   mixins: [LocalizationMixin],
   methods: {
    redirectMain() {
      if (this.$store.getters["auth/isAuth"]) {
        this.$router.push({ name: 'menu', params: { menu: 'info',submenu: 'systeminfo'} }).catch(()=>{});
      }
    }
   }
}
</script>

<style lang="stylus" scoped>
@require '~@/assets/stylus/mixins/mixins';
@require '~@/assets/stylus/vars/variables';
.page-not-found
  flexy(center,center,nowrap,column)
  width 100%
  padding-top 72px
  &__header
    color:$theme-light.primary.base;
    font-weight: 800;
    font-size: 80px;
  &__text
    margin-top 24px
    font-weight: 500;
    font-size: 18px;
  &__btn
    width 147px !important
    margin-top 24px
    +breakpoint(ms-and-down){
      width 100% !important
    }
</style>
