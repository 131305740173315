<template>
  <base-editor
    v-if="items"
    :url="url"
    type="lua"
    :scripts="items"
    :selected-script="selectedScript"
  ></base-editor>
</template>

<script>
import DataApi from '@/services/api/DataApi.js'

export default {
  data() {
    return {
      loading: false,
      items: {},
      selectedScript: ''
    }
  },
  props: {
    url: {
      type: String
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      this.$store.commit('setLoading', true)
      DataApi.get(this.url)
        .then((response) => {
          this.items = response
          const lastScript = window.localStorage.getItem('plc_last_lua_script')
          if (lastScript) {
            this.selectedScript = lastScript
          } else {
            if (this.items) {
              this.selectedScript = Object.keys(this.items)[0]
            }
          }
          this.loading = false
        }).finally(() => {
        this.$store.commit('setLoading', false)
      })
    }
  }
}
</script>
