<template>
<div class="tpl-table">
    <base-btn v-if="expandable" @click="showModal(items.data[0],'add')" class="tpl-table__btn" prefixIcon="plus">
      {{translate('add')}}
    </base-btn>
    <div class="table__wrapper table__wrapper__container">
      <table class="table">
        <tr class="table__row table__row_header">
          <th class="table__item tpl-table__col">{{translate('name')}}</th>
          <th class="table__item tpl-table__col">{{translate('value')}}</th>
          <th  class="table__item tpl-table__col">{{!isMobile ? translate('actions') : ''}}</th>
        </tr>
        <tr class="table__row"  v-for="(item, index) in items.data" :key="index">
          <td class="table__item tpl-table__col">{{translate(item.name)}}</td>
          <td class="table__item tpl-table__col">
            <p v-if="!item.isEdit">{{getValue(item, item.name)}}</p>
            <project-input v-else
               @validate='validate($event,index)'
               :ref="item.name"
               :config='getComponent(item.name)'
               v-model="item.input"
               :is-hidden="getComponentHidingValue(item.name)"
            />
          </td>
          <td class="table__item tpl-table__col icons-col">
            <div v-if="!item.isEdit">
              <svg-icon @click="toogleBtn(item)"  class="table__btn_icon" name='edit'/>
              <svg-icon v-if="expandable" class="table__btn_icon" name='basket'/>
            </div>
            <div v-else>
              <div  v-if="!isMobile" class="tpl-table__btns icons-col">
                <svg-icon :class="{'table__btn_icon_disabled':item.inValid}" @click="toogleBtn(item)" class="table__btn_icon" name='save'/>
                <svg-icon :class="{'table__btn_icon_disabled':item.inValid}" @click="cancel(item)" class="table__btn_icon table__btn_icon_red close-icon"  theme="red" name='close'/>
              </div>
              <svg-icon :class="{'table__btn_icon_disabled':item.inValid}" v-else @click="toogleBtn(item)" class="table__btn_icon" name='check'/>
            </div>
          </td>
        </tr>
      </table>
    </div>

    <VDialog
      v-model='dialog'
      @clickCancel="dialog.state=false"
      @clickSave="onClickSave"
    />
</div>
</template>

<script>
import {addPercent} from '@/helpers/formatedData.js'
import LocalizationMixin from '@/mixins/LocalizationMixin.js'
import UpdateDataMixin from '@/mixins/UpdateDataMixin.js'
import FiltersMixin from '@/mixins/FiltersMixin.js'
import HidingMixin from '@/mixins/HidingMixin.js'
import DataApi from '@/services/api/DataApi.js'
import getNamePath from '@/helpers/substrPath.js'
import breakpoints from '@/utils/breakpoints.js'

const VDialog = () => import('@/components/common/VDialog');

class TplTableItem{
  name=''
  value=''
  isEdit=false
  inValid=true
  input=''
  constructor(key,value){
    this.name=key
    this.value=value
    this.input=value
  }
}
class TplTable{
  data=[]
  constructor(items, keys){
    if (!!keys && keys.length) {
      keys.forEach(key => {
        if (items.hasOwnProperty(key)) {
          this.data.push(new TplTableItem(key,items[key]))
        }
      })
    }
    else {
      for(let key in items){
        this.data.push(new TplTableItem(key,items[key]))
      }
    }

  }
}
export default {
  mixins: [LocalizationMixin,FiltersMixin,UpdateDataMixin, HidingMixin],
  components: {VDialog},
  data(){
    return{
      inputsEdit:[],
      inputs:[],
      namePath:'',
      isEdit:[],
      updateData:true,
      dialog:{
        state:false,
        item:null,
        mode:'update',
        form:{},
        validation:false
      },
    }
  },
  props:{
    url:{
      type:String
    },
    expandable:{
      type:Boolean,
      default:false
    },
    keys: {
      type: Array
    }
  },
  created(){
    this.namePath=getNamePath(this.url)
  },
  computed:{
    screenWidth() {
      return this.$store.state.screen.width;
    },
    isMobile(){
      return this.screenWidth<breakpoints.md
    }
  },
  methods:{
    addPercent,
    toogleBtn(item){
      if(typeof item.value === 'object'){
        this.showModal(item,'update');
      }
      else {
        if(item.isEdit){
          const input=this.$refs[item.name][0]
          if(input){
            input.validate()
            if(!input.$v.$invalid){
              const {name,input, value}=item
              const inputType = this.getComponentType(item.name);
              let inputValue = inputType === 'int10' || inputType === 'port' ? Number(input) : input;
              DataApi.update(this.url,{[name]: Number(value) ? inputValue : input})
                .then(()=>{
                  this.updateData=true
                  item.isEdit=false
                })
            }
          }
        }
        else{
          this.updateData=false
          for(let key in this.items.data) {
            const el=this.items.data;
            if(el[key].name!=item.name) {
              el[key].isEdit=false;
            }
          }
          item.isEdit=true
        }
      }
    },

    cancel(item) {
      item.input=item.value;
      item.isEdit=false;
    },

    fetchData(){
      if(this.updateData){
        this.loading=true
        return DataApi.get(this.url)
        .then((data)=>{
          this.items=new TplTable(data, this.keys)
          this.loading=false
        })
      }
    },

    validate(state,index){
      this.items.data[index].inValid=state
    },

    showModal(item,mode){
      this.dialog.state=true
      this.dialog.mode=mode
      if(mode=='add'){
        this.dialog.item={
          value:{
            name:'',
            value: ''
          }
        },
          this.dialog.form={name:'',...item.value}
      }else{
        this.dialog.item=item
        this.dialog.form={name:'',...item.value}
      }
    },

    updateObjectData() {
      const { form }=this.dialog;
      let url = this.url;
      if (this.dialog.item?.name) {
        url = `${this.url}/${this.dialog.item.name}`
      }

      DataApi.update(url, form)
      .then(()=>{
        this.updateData=true
        this.dialog.state=false
      })
    },

    addItem() {
      const { form }=this.dialog;
      const {name, value}=form

      DataApi.update(this.url,{[name]: value})
      .then(()=>{
        this.dialog.state=false
        this.updateData=true
      })
    },

    onClickSave() {
      if(this.dialog.mode=='add') {
        this.addItem();
      } else {
        this.updateObjectData();
      }
    },

    getValue(item, type){
      if (typeof item.value!=='object') {
        return !this.getComponentHidingValue(type) ? item.value : '********'
      }
      else {
        return ''
      }
    }
  },
}
</script>

<style lang="stylus" scoped>
@require '~@/assets/stylus/mixins/mixins';
@require '~@/assets/stylus/vars/variables';

.tpl-table
  &__col
    width 33%

  &__btns
    flexy(flex-start, flex-start)
    & > *
      &:first-child
        margin-right 16px;

  &__btn
    flexy(center,center)
    width 127px !important
    margin-bottom 24px
    +breakpoint(ms-and-down){
        width 100% !important
    }
  .icons-col
    .table__btn_icon
      margin-right 20px
</style>
