<template>
  <div v-if="inputVal" class="base-dialog">
    <div class="base-dialog__wrapper" v-on-clickaway="hide">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway';
  export default {
    directives: {onClickaway: onClickaway,},
    props:{
      value:{
        type:Boolean
      }
    },
    computed:{
      inputVal: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit("input", value);
        },
      }
    },
    methods:{
      hide(){
        this.inputVal=false
      }
    }

  }
</script>

<style lang="stylus" scoped>
@require '~@/assets/stylus/mixins/mixins';
@require '~@/assets/stylus/vars/variables';
.base-dialog
  flexy(center,center)
  top 0px
  left 0px
  position fixed
  width 100vw
  height 100vh	
  backdrop-filter: blur(2px);
  background rgba(255, 255, 255, 0.2);
  z-index 10000
  &__wrapper
    flexy(center,center)
</style>