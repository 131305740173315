import axios from 'axios'
import qs from 'qs'
import {globalResponseProcessing, catchResponseProcessing} from '@/helpers/responseProcessing.js'

export default class Api {
  static getAccessToken() {
    const token = window.localStorage.getItem('plc-token')
    return token || ''
  }

  static getRefreshToken() {
    const vuex = window.localStorage.getItem('vuex')
    return vuex ? JSON.parse(vuex).auth?.refreshToken : ''
  }

  static getHeaders() {
    if (this.getAccessToken()) {
      return {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAccessToken()}`
      }
    }
    return { 'Content-Type': 'application/json' }
  }

  static apiService = axios.create({
    baseURL: "/",
    // withCredentials : true,
    headers: this.getHeaders(),
    paramsSerializer: (params) =>
      qs.stringify(params, { skipNulls: true, arrayFormat: 'comma' })
  })
}

Api.apiService.interceptors.response.use(
async (response) => {
  let store=await import('@/store')
  store.default.dispatch('hideApiError')

  if(response.config.method==='post' || response.config.method==='put' || response.config.method==='delete') {
    globalResponseProcessing(response.data, store.default)
  }
  return response
},
async function (error) {
  let store=await import('@/store')

  if (error.response?.status === 401) {
    store.default.dispatch('auth/logout')
  } else {
    catchResponseProcessing(error, store.default)
  }

  return Promise.reject(error)
})
